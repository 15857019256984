import React, { useEffect, useState } from "react";
import Styles from "../../Categories/Categories.module.css";
import axios from "axios";
import { DOMAIN } from '../../../../constants/envUrl'
import { Button } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";

import {
  UpdateProviderCategoriesListOptions,
  getAllCategories,
  getProviderCategoriesList,
  getProviderCategoriesListOptions,
  addMainCategoriesToProductCategory,
  gellAllCategoriesByProductCat,
  updateProductCategory,
  uploadFile,
  createProductCategory,
  getWishlistCategories,
  createWishlistCategory,
  updateWishlistCategory,
  getMainCategories,

} from "../../../../config/api";
import { useParams } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";


import noPeopleImg from '../../../assets/Images/2.png'
import { add } from "date-fns";
import AddWishlist from "../../edit-wishlist/AddWishlist";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Categories = ({ langConfig, langData }) => {
  const [allCatByProduct, setAllCatByProduct] = useState([])
  const [categoriesList, setCategoriesList] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [wishlistCategories, setWishCategories] = useState([])
  const [editingElement, setEditingElement] = useState(null)
  const [editableValue, setEditableValue] = useState('')
  const [newProductCat, setNewProductCat] = useState({
    name: "",
    imageLink: "",
    iconLink: "",
    description: ""
  })

  console.log('wishlistCategories: ', wishlistCategories)

  const [isAddCategory, setIsAddCategory] = useState(false)
  const [mainCategories, settMainCategories] = useState([])

  const handleGetWishlistCategories = async () => {
    try {
      const response = await axios.get(getWishlistCategories());
      const { data } = response;
      if (data.result) {
        console.log('handleGetMainCategories: ', data)
        setWishCategories(data.mainCategoriesList)
      }

    } catch (error) {
      console.log(error)
    }
  }

  const formNewWishCategory = async (key, event) => {

    if (key === 'imageLink' || key === 'iconLink') {

      const file = event.target.files[0]

      try {
        const formData = new FormData();
        formData.append('image', file); // Assuming the file is an instance of File or Blob

        const response = await axios.post(uploadFile(), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status !== 200) return
        const data = response.data;
        // setNewProductCat(prevProdCat => ({ ...prevProdCat, [key]: `${DOMAIN}/server-images/${data}` }))
        setNewProductCat(prevProdCat => ({ ...prevProdCat, [key]: `/server-images/${data}` }))
        console.log("formNewWishCategory: ", data)
      } catch (err) {
        console.log(err)
      }

    } else {
      setNewProductCat(prevProdCat => ({ ...prevProdCat, [key]: event.target.value }))
    }
  }

  const createNewWishlistCategory = async () => {

    try {
      const { data } = await axios.post(createWishlistCategory(), newProductCat)
      console.log('createNewWishlistCategory', data)
      if (data.result) {
        handleGetWishlistCategories()
      }
    } catch (error) {
      console.log(error)
    }

    setIsAddCategory(false)
  }

  const navigate = useNavigate();

  const editElmentHandler = (id) => {
    // const input = document.querySelector(`#input-${id}`)
    // input.focus()
    setEditingElement(id)
    console.log('editElmentHandler: ', id)
  }

  const uploadImage = async (event, obj, key) => {

    const file = event.target.files[0]

    try {
      const formData = new FormData();
      formData.append('image', file); // Assuming the file is an instance of File or Blob

      const response = await axios.post(uploadFile(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const data = response.data;
      if (response.status !== 200) return
      setEditableValue(`/server-images/${data}`)
      // setEditableValue(`${DOMAIN}/server-images/${data}`)

      const { description, categoryName, categoryImage, categoryIcon, englishName, id } = obj;

      const updatedObject = {
        id: id,
        name: categoryName,
        imageLink: key === 'imageLink' ? `/server-images/${data}` : categoryImage,
        iconLink: key === 'iconLink' ? `/server-images/${data}` : categoryIcon,
        description: description,
        englishName: englishName
      }

      const responseUpdate = await axios.post(updateWishlistCategory(), updatedObject)

      console.log('response.result', response)
      if (responseUpdate.data.result) {
        setEditingElement(null)
        setEditableValue('')
        handleGetWishlistCategories()
      }

      console.log('uploadImage: ', data, response)
      return data;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const deleteCategory = async (id) => {
    //Need to add real endpoint when have one
    // const response = await axios.post(deleteCategoryEndp(), id)
    // if (response.result) {
    //   alert()
    //   fetchAllCatByProduct()
    // } else {
    //   alert("Something went wrong while deleting category")
    // }

  }

  const handleCategoryChecked = (wishlist, mainCat) => {
    console.log('handleCategoryChecked: ', "wishlist: ", wishlist, "mainCat: ", wishlist)
    if (!wishlist.mainCategory) return false
    const mainCatList = JSON.parse(wishlist.mainCategory)
    if (Array.isArray(mainCatList)) {
    return mainCatList?.find(cat => cat.id == mainCat.id)?.checked
    }
 
  }

  const updateWishlistCategoryHandler = async (obj, key) => {

    const { mainCatNames } = await handleGetMainCategories()

    const {
      description,
      categoryImage, categoryIcon, categoryName, id, englishName } = obj;
    console.log('mainCatNames', mainCatNames)
    const updatedObject = {
      id: id,
      name: key === 'name' ? editableValue : categoryName,
      imageLink: key === 'imageLink' ? editableValue : categoryImage,
      iconLink: key === 'iconLink' ? editableValue : categoryIcon,
      description: key === 'description' ? editableValue : description,
      englishName: key === 'englishName' ? editableValue : englishName,
      // mainCategory: JSON.stringify(mainCatNames)
    }

    console.log('updateWishlistCategoryHandler: obj', obj,
      "key:", key,
      "editableValue: ", editableValue,
      "updatedObject:", updatedObject)

    try {
      //UNCOMMENT WHEN HAVE REAL ENDPOINT FOR MAIN CATEGORIES UPDATE
      const response = await axios.post(updateWishlistCategory(), updatedObject)

      console.log('response.result', response)
      if (response.data.result) {
        setEditingElement(null)
        setEditableValue('')
        handleGetWishlistCategories() // 
      } else {
        alert('Error updating main categories')
      }

    } catch (error) {
      console.log('Error updating main categories:', error)
      alert('Error updating main categories:', error)
    }

    // const newObj = {...obj, [key] : editableValue}
    console.log('updateWishlistCategory: ', updatedObject)
  }

  const editWishlist = (id) => {
    navigate(`/edit-wishlist/${id}`);
  };
  const addWishlist = () => {
    
    navigate(`/add-wishlist`);
  };

  const fetchCategoriesList = async () => {
    try {
      setCategoriesLoading(true);
      // const response = await axios.get(getProviderCategoriesList(id));
      const response = await axios.get(getProviderCategoriesListOptions());
      const data = response.data;
      setCategoriesList(data.data);
      // console.log("categoriesList", data.data);
      setCategoriesLoading(false);
    } catch (error) {
      console.error(error);
      setCategoriesLoading(false);
    }
  };

  const fetchAllCatByProduct = async () => {
    const response = await axios.post(gellAllCategoriesByProductCat());
    if (response.status == 200) {
      const { data } = response;
      // console.log('fetchAllCatByProduct: ', response.data)
      setAllCatByProduct(data)
    }
  }

  console.log('wishlistCategories',wishlistCategories)

  const handleCheckOption = async (event, whishlistId, mainCatId, mainCatList, obj) => {
    console.log('handleaCheckOption: ', event.target.checked, 'mainCatId: ', mainCatId, "mainCatList:", mainCatList)
    //getting all main categories available to be able to add new categories if were added
    if (mainCatList) {
      mainCatList = JSON.parse(mainCatList)
    }

    const { mainCatNames } = await handleGetMainCategories()

    const {
      description,
      categoryImage,
      categoryIcon,
      categoryName,
      id,
      englishName
    } = obj;


    let updatedMainCatList = null;
    if (Array.isArray(mainCatList)) {
      updatedMainCatList = mainCatList.map(cat => {
        if (mainCatId == cat.id) {
          return { ...cat, checked: !event.target.checked };
        } else {
          return cat;
        }
      });
      // Use updatedMainCatList as needed
    } else {
      // Handle the case where _mainCatList is not an array
      console.error("_mainCatList is not an array");
    }


    // loop through main categories and if there is the new one that does not exist in the wishlist I retirn it with default value checked: false;
    const resultArray = mainCatNames.map(item => {
      const matchingObject = updatedMainCatList?.find(obj => obj.id === item.id);
      return matchingObject || { id: item.id, checked: false, name: item.name };
    });

    console.log('resultArray', resultArray, 'updatedMainCatList: ', updatedMainCatList)

    const updatedObject = {
      id: id,
      name: categoryName,
      imageLink: categoryImage,
      iconLink: categoryIcon,
      description: description,
      englishName: englishName,
      mainCategory: JSON.stringify(resultArray)
    }

    // update wishlist`s mainCategory array of categories with the changed value of checkbox for current category
    const updatedWishlistCategories = [...wishlistCategories].map(wishlist => {
      if (wishlist.id == id) {
        return updatedObject
      } else {
        return wishlist
      }
    })

    // setWishCategories(updatedWishlistCategories)
    console.log('updatedWishlistCategories',updatedWishlistCategories)

    try {
      const response = await axios.post(updateWishlistCategory(), updatedObject)

      console.log('response.result', response)
      if (response.data.result) {
        // setEditingElement(null)
        // setEditableValue('')
        handleGetWishlistCategories() // 
      } else {
        alert('Error updating main categories')
      }

    } catch (error) {
      console.log('Error updating main categories:', error)
      alert('Error updating main categories:', error)
    }


  };

  const handleGetMainCategories = async () => {
    try {
      const response = await axios.get(getMainCategories());
      const { data } = response;
      let mainCatNames = null;
      let allMainCategpries = null;
      if (data.result) {

        mainCatNames = data.mainCategoriesList.map(category => {
          return { id: category.id, name: category.categoryName, checked: false };
        })

        // console.log('mainCatNames: ', mainCatNames)
        settMainCategories(data.mainCategoriesList)
      }
      return { mainCatNames, allMainCategpries };
    } catch (error) {
      console.log(error)
      return { mainCatNames: null, allMainCategpries: null };
    }
  }


  useEffect(() => {
    handleGetMainCategories()
  }, [])


  useEffect(() => {
    fetchAllCatByProduct()
    handleGetWishlistCategories()

  }, []);

  return (
    <div className={Styles.containerUser}>
      <div className={Styles.headingUser}>
        {/*<h1>
          {langData[langConfig.userType][langConfig.lang].tab2.wishlistCat.subTabName}
        </h1>*/}
      </div>
      <div className="control-btns" style={{display: 'flex', justifyContent: 'left'}}>
                 
                          <button
                            onClick={() => {
                             addWishlist()
                            }
                            }
                            className={`${Styles.btnStyle}`}
                          >
                             הוסף אירוע
                          </button>
                      
                      </div>

      <div className={Styles.filterBox}>
        <div className="d-flex justify-content-end align-items-center">
        </div>
      </div>

      <div className="card mt-5">
        <div className="card-header">
          <h2 className="card-title">
אירועים          </h2>
        </div>
        <div className="card-body">
          <div className={Styles.tableResponsive}>
            <table className={`${Styles.table} ${Styles.tableStriped}`}>
              <thead>
                <tr>
                  <th>
                    {langData[langConfig.userType][langConfig.lang].tab2.wishlistCat.table.mainCat}
                  </th>
                  <th>
שם האירוע                  </th>
                  <th>
                    {langData[langConfig.userType][langConfig.lang].tab2.wishlistCat.table.engName}
                  </th>
                  <th>
ברכה           
       </th>
                  <th>
איקון                  </th>
                  <th>
תמונה                  </th>
                  {/* <th>
                    {langData[langConfig.userType][langConfig.lang].tab2.wishlistCat.table.catDate}
                  </th> */}
                  <th></th>

                </tr>
              </thead>
              {!categoriesLoading ? (
                <tbody>
                  {wishlistCategories?.length > 0 ? (
                    wishlistCategories?.map((item, index) => {

                      function returnActiveCat() {
                        const wishlistMainCategory = item.mainCategory ? JSON.parse(item.mainCategory) : []
                        const activeSelectCategories = Array.isArray(wishlistMainCategory)?wishlistMainCategory?.filter((cat) => cat.checked)?.map(cat => cat.name): ""
                        console.log(wishlistMainCategory, 'wishlistMainCategory')
                        console.log('activeSelectCategories', activeSelectCategories)
                        return Array.isArray(wishlistMainCategory)? activeSelectCategories.join(', '): ""
                      }

                      return (
                        <>

                          <tr key={index}>
                            <td>
                              <FormControl sx={{ m: 1, width: 300 }}>
                                <InputLabel
                                  id={`demo-multiple-checkbox-label-${index}`}
                                >
                                  Categories
                                </InputLabel>
                                <Select
                                  labelId={`demo-multiple-checkbox-label-${index}`}
                                  id={`demo-multiple-checkbox-${index}`}
                                  multiple
                                  value={mainCategories}
                                  // onChange={(event) =>
                                  //   handleOptionChange(event, index, itemInner.productCategory.id)
                                  // }
                                  input={<OutlinedInput label="Categories" />}
                                  renderValue={() => returnActiveCat()}
                          
                                  MenuProps={MenuProps}
                                >


                                  {mainCategories.map((option, indexInner) => {
                                    const checked = handleCategoryChecked(item, option)

                                    return (
                                      <MenuItem key={indexInner}
                                        value={option.categoryAdminName}
                                      >
                                        <Checkbox
                                          checked={option.checked}
                                          checked={checked}
                                          onChange={(event) => handleCheckOption(event, item.id, option.id, item.mainCategory, item)}
                                        />
                                        <ListItemText primary={option.categoryAdminName} />
                                      </MenuItem>
                                    )
                                  })
                                  }
                                </Select>
                              </FormControl>
                            </td>
                            <td>

                              {editingElement == `${index}0` ? (
                                <>
                                  <input
                                    onChange={(e) => setEditableValue(e.target.value)}
                                    id={`input-${index}0`} type="text" defaultValue={item.categoryAdminName} />
                                  <button onClick={() => updateWishlistCategoryHandler(item, 'name')}>Save</button>
                                </>
                              ) : (
                                <>
                                  <span>
                                    {item.categoryName}
                                  </span>
                                  <EditIcon onClick={() => editElmentHandler(`${index}0`)} />
                                </>
                              )}
                            </td>
                            <td>

                              {editingElement == `${index}1` ? (
                                <>
                                  <input
                                    onChange={(e) => setEditableValue(e.target.value)}
                                    id={`input-${index}1`} type="text" defaultValue={item.englishName} />
                                  <button onClick={() => updateWishlistCategoryHandler(item, 'englishName')}>Save</button>
                                </>
                              ) : (
                                <>
                                  <span>
                                    {item.englishName}
                                  </span>
                                  <EditIcon onClick={() => editElmentHandler(`${index}1`)} />
                                </>
                              )}
                            </td>
                            <td>
                              {editingElement == `${index}2` ? (
                                <>
                                  <input
                                    onChange={(e) => setEditableValue(e.target.value)}
                                    id={`input-${index}2`} type="text" defaultValue={item.description} />
                                  <button onClick={() => updateWishlistCategoryHandler(item, 'description')}>Save</button>
                                </>
                              ) : (
                                <>
                                  <span>
                                    {item.description}
                                  </span>
                                  <EditIcon onClick={() => editElmentHandler(`${index}2`)} />
                                </>
                              )}
                            </td>
                            <td >
                              <span className={Styles.tableImageCover}>
                                <img className={`${Styles.tableImage}`} src={`${DOMAIN}${item.categoryIcon}`} alt="" />
                              </span>

                              <label>
                                <input
                                  style={{ width: 0, position: "absolute", left: "100%" }}
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => uploadImage(e, item, 'iconLink')}
                                  id={`input-${index}3`}
                                />
                                <EditIcon onClick={() => editElmentHandler(`${index}3`)} />
                              </label>

                            </td>
                            <td>
                              <img className={`${Styles.tableImage} img-people`} src={`${DOMAIN}${item.categoryImage}`} alt="" />
                              <img className={`${Styles.tableImage} img-no-people`} src={noPeopleImg} alt="" />
                              <label>
                                <input
                                  style={{ width: 0, position: "absolute", left: "100%" }}
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => uploadImage(e, item, 'imageLink')}
                                  id={`input-${index}4`}
                                />
                                <EditIcon onClick={() => editElmentHandler(`${index}$4`)} />
                              </label>

                            </td>

                            <td>
                            <Button
                              onClick={() => editWishlist(item.id)}
                            >
                              עריכה
                            </Button>
                          </td>


                          {/*  <td>
                               <button
                                className={`${Styles.btnStyle}`}
                                onClick={() => deleteCategory(item.id)}>
                                {langData[langConfig.userType][langConfig.lang].tab2.wishlistCat.table.deleteBtn}
                              </button> 
                            </td>*/}
                          </tr>

                        </>

                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={"3"} className="text-center py-5">
                        No data
                      </td>
                    </tr>
                  )}

                  {isAddCategory && (
                    <tr
                    >
                      <td>
                      </td>
                      <td>
                        <label>
                          <p>
                          {langData[langConfig.userType][langConfig.lang].tab2.wishlistCat.table.catName}

                          </p>
                          <input
                            onChange={(e) => formNewWishCategory('name', e)}
                            type="text" />
                        </label>

                      </td>
                      <td>
                        <label>
                          <p>
                          {langData[langConfig.userType][langConfig.lang].tab2.wishlistCat.table.engName}
                          </p>
                          <input
                            onChange={(e) => formNewWishCategory('englishName', e)}
                            type="text" />
                        </label>

                      </td>
                      <td>
                        <label>
                          <p>
                          {langData[langConfig.userType][langConfig.lang].tab2.wishlistCat.table.catDesc}
                          </p>
                          <input
                            onChange={(e) => formNewWishCategory('description', e)}
                            type="text"
                          />
                        </label>
                      </td>
                      <td>
                        {newProductCat.iconLink ? (
                          <>
                            {/* <img className={`${Styles.tableImage}`} src={`${DOMAIN}${newProductCat.iconLink}`} alt="" /> */}
                            <img className={`${Styles.tableImage}`} src={noPeopleImg} alt="" />
                          </>

                        ) : (
                          <label>
                            <input
                              style={{ width: 0, position: "absolute", left: "100%" }}
                              type="file"
                              accept="image/*"
                              onChange={(e) => formNewWishCategory('iconLink', e)}
                            />
                            <span
                              style={{ display: "block", width: "max-content" }}
                              className={`${Styles.btnStyle}`}

                            >
                              {langData[langConfig.userType][langConfig.lang].tab2.wishlistCat.table.uploadIcon}
                            </span>
                          </label>
                        )}


                      </td>
                      <td>
                        {
                          newProductCat.imageLink ? (
                            <>
                              <img className={`${Styles.tableImage} img-people`} src={`${DOMAIN}${newProductCat.imageLink}`} alt="" />
                              <img className={`${Styles.tableImage} img-no-people`} src={noPeopleImg} alt="" />
                            </>

                          ) : (
                            <label>
                              <input
                                style={{ width: 0, position: "absolute", left: "100%" }}
                                type="file"
                                accept="image/*"
                                onChange={(e) => formNewWishCategory('imageLink', e)}
                              />
                              <span
                                style={{ display: "block", width: "max-content" }}
                                className={`${Styles.btnStyle}`}

                              >
                                {langData[langConfig.userType][langConfig.lang].tab2.wishlistCat.table.uploadImage}
                              </span>
                            </label>
                          )
                        }

                      </td>
                      {/* <td></td> */}
                      <td>
                        <button
                          onClick={createNewWishlistCategory}
                          className={`${Styles.btnStyle}`}
                        >
                          {langData[langConfig.userType][langConfig.lang].tab2.wishlistCat.table.saveCategory}
                        </button>
                      </td>
                    </tr>)}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>

                    </td>
                    <td>

                    </td>
                    <td>
             
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan={"3"}
                      className="text-center"
                      style={{
                        height: "200px",
                      }}
                    >
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      ></div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Categories;
