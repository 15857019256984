import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Styles from '../Categories/Categories.module.css';
import { DOMAIN } from '../../../constants/envUrl';
import EditIcon from '@mui/icons-material/Edit';

import {
  getWishlistCategoriesById,
  updateWishlistCategory,
  removeWishlistCategory,
  getMainCategories,
  uploadFile,
  createWishlistCategory,

} from '../../../config/api';
import {
  TextField,
  Button,
  Typography,
  Box,
  OutlinedInput,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const AddWishlist = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [wishlistDetails, setWishlistDetails] = useState({
    id: '',
    categoryName: '',
    englishName: '',
    categoryDescription: '',
    categoryIcon: '',
    categoryImage: '',
    mainCategory: [],
  });
  const [mainCategories, setMainCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isEditing, setIsEditing] = useState(true);
  const [icon, setIcon] = useState('');
  const [image, setImage] = useState('');


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(getWishlistCategoriesById(id));
        const wishListCategory = response.data.wishlistCategory;

        setWishlistDetails({
          id: id,
          categoryName: wishListCategory?.categoryName || ' ',
          englishName: wishListCategory?.englishName,
          categoryDescription: wishListCategory?.description || ' ',
          categoryIcon: wishListCategory?.categoryIcon,
          categoryImage: wishListCategory?.categoryImage,
          mainCategory: wishListCategory?.mainCategory || [],
        });
        setIcon(`${wishListCategory?.categoryIcon}`);
        setImage(`${wishListCategory?.categoryImage}`);
      
        if (wishListCategory?.mainCategory) {
          let mainCatList = JSON.parse(wishListCategory.mainCategory);
          if (Array.isArray(mainCatList)) {
            mainCatList = mainCatList
              .filter((item) => item.checked)
              .map((item) => item.name);
			  console.log(mainCatList)

            setSelectedCategories(mainCatList);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWishlistDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
	  
    }));
  };

  const handleSave = async () => {
   
    try {
      const mainCategoryArray = mainCategories.map((category) => ({
        id: category.id,
        checked: selectedCategories.includes(category.name),
        name: category.name,
      }));

      const response = await axios.post(updateWishlistCategory(), {
        id: id,
        categoryName: wishlistDetails.categoryName,
        englishName: wishlistDetails.englishName,
        description: wishlistDetails.categoryDescription,
        imageLink: image,
        iconLink: icon,
        mainCategory: JSON.stringify(mainCategoryArray),
      });

      if (response.data.result) {
    //    alert('Success');
    navigate('/product-categories-management');

      } else {
        alert('Error updating wishlist category');
      }
    } catch (error) {
      console.error('Error updating wishlist category:', error);
      alert(`Error updating wishlist category: ${error.message}`);
    }
  };

  const handleDelete = async () => {

        navigate('/product-categories-management');
    
  };

  const handleCheckOption = (event) => {
    setSelectedCategories(event.target.value);
  };

  const handleGetMainCategories = async () => {
    try {
      const response = await axios.get(getMainCategories());

      const { data } = response;
      let mainCatNames = null;
      if (data.result) {
        mainCatNames = data.mainCategoriesList.map((category) => ({
          id: category.id,
          name: category.categoryName,
          checked: false,
        }));
        setMainCategories(mainCatNames);
      }
      return { mainCatNames };
    } catch (error) {
      console.log(error);
      return { mainCatNames: null };
    }
  };

  useEffect(() => {
    handleGetMainCategories();
  }, []);

  function returnActiveCat() {
    return Array.isArray(selectedCategories)
      ? selectedCategories.join(', ')
      : '';
  }

  const uploadImage = async (event, type) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append('image', file);

        const response = await axios.post(uploadFile(), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        debugger

        const data = response.data;
        if (response.status !== 200) return;
        if (type === 'icon') setIcon(`/images/${data}`);
        if (type === 'image') setImage(`/images/${data}`);
      } catch (error) {
        console.error('Error uploading image:', error);
        throw error;
      }
    } else {
      console.error('No file selected');
    }
  };

  const createNewWishlistCategory = async () => {

    try {
      const mainCategoryArray = mainCategories.map((category) => ({
        id: category.id,
        checked: selectedCategories.includes(category.name),
        name: category.name,
      }));

      const { data } = await axios.post(createWishlistCategory(), {
        name: wishlistDetails.categoryName,
        englishName: wishlistDetails.englishName,
        description: wishlistDetails.categoryDescription,
        imageLink: image,
        iconLink: icon,
         mainCategory: JSON.stringify(mainCategoryArray),
      })
      console.log('createNewWishlistCategory', data)
      if (data.result) {
      }
    } catch (error) {
      console.log(error)
    }

  }
  return (
    <Box
    style={{margin: '60px'}}
 
  >
    <Typography
      variant="h5"
     sx={{ color: '#154360', marginBottom: '15px', fontWeight: '700', marginBottom: '50px' }}
    >
    הוספת אירוע
    </Typography>
    <form style={{width: '90%'}}>
   
 <div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">שם אירוע</label>
        <div className="col-sm-10"style={{width: '50%'}}>
            <input
                type="text"
                name="categoryName"
                value={wishlistDetails.categoryName}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="form-control"
            />
        </div>
    </div>
   

<div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">שם באנגלית</label>
        <div className="col-sm-10"style={{width: '50%'}}>
            <input
                type="text"
                name="englishName"
                value={wishlistDetails.englishName}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="form-control"
            />
        </div>
    </div>

    <div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">קטגוריות</label>
        <div className="col-sm-10"style={{width: '50%'}}>
               <FormControl sx={{ width: 500, marginTop: 1}}>

          <InputLabel id="demo-multiple-checkbox-label">
            קטגוריות 
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedCategories}
            onChange={handleCheckOption}
            input={<OutlinedInput label="Categories" />}
            renderValue={() => returnActiveCat()}
            MenuProps={MenuProps}
          >
            {mainCategories.map((option, indexInner) => (
              <MenuItem key={indexInner} value={option.name}>
                <Checkbox checked={selectedCategories.includes(option.name)} />
                <ListItemText primary={option.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
    </div>

    <div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">ברכה</label>
        <div className="col-sm-10"style={{width: '50%'}}>

<TextField
    type="text"
    name="categoryDescription"
    value={wishlistDetails.categoryDescription}
    onChange={handleInputChange}
    disabled={!isEditing}
    className="form-control"
    multiline // Allow multiline input
    rows={4} // Set the number of visible rows
    InputProps={{
        style: {
            height: 'auto', // Allow the height to adjust based on content
            overflowWrap: 'break-word' // Start new line when necessary
        }
    }}
/>
        </div>
    </div>


    <div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">איקון</label>
        <div className="col-sm-10"style={{width: '50%'}}>
        <span className={Styles.tableImageCover}>
      <img className={`${Styles.tableImage}`} src={`${DOMAIN}${icon}`} alt="" />
    </span>

    <label>
      <input
        style={{ display: 'none' }}
        type="file"
        accept="image/*"
        onChange={(e) => uploadImage(e, 'icon')}
      />

      <EditIcon onClick={(e) => uploadImage(e, 'icon')} />
    </label>
        </div>
    </div>


    <div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">תמונה</label>
        <div className="col-sm-10"style={{width: '50%'}}>
           
    <span >
      <img className={`${Styles.tableImage}`} src={`${DOMAIN}${image}`} alt="" />
    </span>

    <label>
      <input
        style={{ display: 'none' }}
        type="file"
        accept="image/*"
        onChange={(e) => uploadImage(e, 'image')}
      />

      <EditIcon onClick={(e) => uploadImage(e, 'image')} />
    </label>
        </div>
    </div>




<div style={{width: '70%', marginTop: '50px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
    <Button
        variant="outlined"
        color="primary"
        onClick={createNewWishlistCategory}
        style={{ fontWeight: 'bold', width: '150px' }}
        disabled={!isEditing}
    >
        הוסף אירוע
    </Button>
    <Button
        variant="outlined"
        color="error"
        onClick={handleDelete}
        style={{ fontWeight: 'bold', width: '150px' }}
        disabled={!isEditing}
    >
     ביטול
    </Button>
</div>

      
   
      </form>
    </Box>
  );
};

export default AddWishlist;
