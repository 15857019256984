import React, { useEffect, useState, useRef, useCallback } from "react";
import Styles from "./Categories.module.css";
import CategoryRow from "./CategoryRow"
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  UpdateProviderCategoriesListOptions,
  getProviderCategoriesList,
  getProviderCategoriesListOptions,
} from "../../../config/api";
import { useLocation } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 9.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

const Categories = ({
  isAdmin,
  id,
  setChangeInCategories,
  setSavedCategories,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [categoriesList, setCategoriesList] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [categoriesOptionsList, setCategoriesOptionsList] = useState([]);
  const [visibleOptionsCount, setVisibleOptionsCount] = useState(10);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const selectRef = useRef(null);
  const searchInputRef = useRef(null); // Use ref for the search input

  const handleOptionChange = useCallback(
    (event, option, index) => {
      setCategoriesList((prevCategoriesList) => {
        const updatedList = [...prevCategoriesList];
        const oldSystemCategories = updatedList[index].systemCategories.filter(
          (cat) => cat.id !== option.id
        );
        const updatedOption = { ...option, isChecked: event.target.checked };
        updatedList[index].systemCategories = [
          ...oldSystemCategories,
          updatedOption,
        ];
        updatedList[index].systemCategoryId = option.id;
        return updatedList;
      });
    },
    []
  );

  const handleChange = useCallback(() => {
    setChangeInCategories(true);
    setSavedCategories(false);
  }, [setChangeInCategories, setSavedCategories]);

  const combinedHandler = useCallback(
    (event, option, index) => {
      handleOptionChange(event, option, index);
      handleChange();
    },
    [handleOptionChange, handleChange]
  );

  const fetchCategoriesList = async () => {
    try {
      setCategoriesLoading(true);
      const response = await axios.get(getProviderCategoriesList(id));
      const data = response.data;

      const updatedCategoriesList = data.data.map((item) => ({
        ...item,
        systemCategories:
          item.systemCategories.length > 0
            ? item.systemCategories
            : categoriesOptionsList.map((option) => ({
                ...option,
                isChecked: false,
              })),
      }));

      setCategoriesList(updatedCategoriesList);
      setFilteredCategories(updatedCategoriesList); // Initialize filteredCategories
      setCategoriesLoading(false);
    } catch (error) {
      console.error(error);
      setCategoriesLoading(false);
    }
  };

  const fetchCategoriesListOptions = async () => {
    try {
      setCategoriesLoading(true);
      const response = await axios.get(getProviderCategoriesListOptions());
      const data = response.data;
      setCategoriesOptionsList(data.data);
      setCategoriesLoading(false);
    } catch (error) {
      console.error(error);
      setCategoriesLoading(false);
    }
  };

  const updateCategoriesListOptions = async () => {
    try {
      setCategoriesLoading(true);
      await axios.post(UpdateProviderCategoriesListOptions(), categoriesList);
      setSavedCategories(true);
      setCategoriesLoading(false);
      await fetchCategoriesList();
    } catch (error) {
      console.error(error);
      setCategoriesLoading(false);
    }
  };

  const openCategoryProducts = (id, name) => {
    navigate(`/selected-category-product/${id}`, {
      state: { categoryName: name },
    });
  };

  const performFiltering = useCallback(() => {
    const value = searchInputRef.current.value;
    if (value.length < 2) {
      setFilteredCategories(categoriesList);
    } else {
      const filtered = categoriesList.map((category) => ({
        ...category,
        systemCategories: category.systemCategories.filter((option) =>
          option.adminName.toLowerCase().includes(value.toLowerCase())
        ),
      }));
      setFilteredCategories(filtered);
    }
  }, [categoriesList]);

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop <=
      event.target.clientHeight + 1;
    if (bottom) {
      setVisibleOptionsCount((prevCount) => prevCount + 10);
    }
  };

  useEffect(() => {
    fetchCategoriesListOptions().then(fetchCategoriesList);
  }, []);

  useEffect(() => {
    setFilteredCategories(categoriesList);
  }, [categoriesList]);

  return (
    <div className={Styles.containerUser}>
      <div className={Styles.filterBox}>
        <div className="d-flex justify-content-end align-items-center">
          <button className={Styles.btnStyle} onClick={updateCategoriesListOptions}>
            שמור
          </button>
        </div>
      </div>

      <div className="card mt-5">
        <div className="card-header"></div>
        <div className="card-body">
          <div className={Styles.tableResponsive}>
            <table className={`${Styles.table} ${Styles.tableStriped}`}>
              <thead>
                <tr>
                  <th>{isAdmin ? "קטגוריות מנהל" : "User Category Title"}</th>
                  <th>קטגורית ספק</th>
                  <th>קטגורית מערכת</th>
                  {!isAdmin && <th></th>}
                </tr>
              </thead>
              {!categoriesLoading ? (
                <tbody>
                  {filteredCategories.length > 0 ? (
                    filteredCategories.map((item, index) => (
                      <CategoryRow
                        key={index}
                        item={item}
                        index={index}
                        isAdmin={isAdmin}
                        setChangeInCategories={setChangeInCategories}
                        setSavedCategories={setSavedCategories}
                        categoriesList={categoriesList}
                        setCategoriesList={setCategoriesList}
                        openCategoryProducts={openCategoryProducts}
                        handleScroll={handleScroll}
                        selectRef={selectRef}
                        searchInputRef={searchInputRef}
                        performFiltering={performFiltering}
                        combinedHandler={combinedHandler}
                        visibleOptionsCount={visibleOptionsCount}
                      />
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center py-5">
                        No data
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan="3"
                      className="text-center"
                      style={{ height: "200px" }}
                    >
                      <div className="spinner-border text-primary" role="status"></div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
