import React, { useEffect, useState } from "react";
import axios from "axios";
import Styles from "../User-Data/User.module.css";
import { getUser, enableDisableSingleUser } from "../../../config/api"; // Import the function here
import TableProfiles from "../../components/Tables/TableProfiles";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';

const Profiles = ({ isAdmin, userId, langConfig, langData }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = location.pathname;

  const [heading, setHeading] = useState(
    pathName === "/provider" ? "Providers" : pathName === '/' ? "Users" : pathName === '/product-list' ? 'Products list' : ''
  );
  const [tableType, setTableType] = useState(
    pathName === "/provider" ? "Provider" : "User"
  );
  const [enable, setEnable] = useState(true);
  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Separate state for filtered data
  const [searchTerm, setSearchTerm] = useState(""); // New state for search term
  const [requestParams, setRequestParams] = useState({
    page: 1,
    pageSize: 0,
    totalRecords: 0,
    sortIndex: -1,
    search: "",
    sortBy: "",
    userType: tableType,
    enableDisableUsers: true,
    datetime: "",
  });
  const [dataLoading, setDataLoading] = useState(false);

  const fetchData = async () => {
    try {
      setDataLoading(true);
      const response = await axios.post(getUser(), requestParams);
      const data = response.data;

      if (isAdmin) {
        setUserData(data.usersList);
        setFilteredData(data.usersList); // Set both userData and filteredData
      } else {
        const userListById = data.usersList?.filter(user => user.id == userId);
        setUserData(userListById);
        setFilteredData(userListById); // Set both userData and filteredData
      }
      setDataLoading(false);
    } catch (error) {
      console.error(error);
      setDataLoading(false);
    }
  };

  const updateUser = async (userId, IsEnable) => {
    try {
      const response = await axios.get(enableDisableSingleUser(userId, IsEnable));
      if (response.status === 200) {
        fetchData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!dataLoading) {
      fetchData();
    }
  }, [requestParams]);

  useEffect(() => {
    setHeading(location.pathname === "/" ? "Users" : "Providers");
    setTableType(location.pathname === "/" ? "User" : "Provider");
    setRequestParams({
      ...requestParams,
      userType: location.pathname === "/" ? "User" : "Provider",
    });
  }, [location.pathname]);

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const filteredData = userData.filter(user => 
      user.firstName.toLowerCase().includes(e.target.value.toLowerCase()) || 
      user.lastName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData(filteredData); // Update the filteredData state
  };

  return (
    <>
      <div className={Styles.containerUser}>
     
        
      <TextField
        label="חפש שם ספק"
        variant="outlined"
        onChange={handleSearchChange}

      />
        <TableProfiles
          data={filteredData} // Pass the filteredData to TableProfiles
          tableLoading={dataLoading}
          heading={heading}
          type={tableType}
          updateUser={updateUser}
          fetchData={fetchData}
          isAdmin={isAdmin}
          langData={langData}
          langConfig={langConfig}
        />
      </div>
    </>
  );
};

export default Profiles;
