import React, { useState, useEffect, useRef } from 'react'
import Styles from './Orders.module.css'
import { getProviderOrders, updateOrderStatus, getProvidersReport, getAllCompleteProvidersOrders } from '../../../config/api';
import TableOrders from '../../components/Tables/TableOrdersProvider';
import axios from 'axios';
import Moment from "moment";
import { DateRangePicker } from 'rsuite';
import TableOrdersAdmin from '../../components/Tables/TableOrdersAdmin';
import TableOrdersProvider from '../../components/Tables/TableOrdersProvider';
import { Form, Button } from 'react-bootstrap'
import TextField from '@mui/material/TextField';
import { format } from 'date-fns';
import Reports from '../../pages/reports/Reports';
import ReportsGeneral from '../../pages/reports/ReportsGeneral';


const orderStatuses = ["delivered", "paidProvider"]


function getDateComponents(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth(); // Months are zero-indexed
  const day = date.getDate();
  return { year, month, day };
}




let filteredOrders = []
export default function ReportsManagement({ userId, isAdmin, langConfig, langData }) {
  const currentdateTime = Moment();
  const dateRangeRef = useRef(null);
  const [heading, setHeading] = useState('Orders');
  const [dataLoading, setDataLoading] = useState(false);
  const [orders, setOrders] = useState([])

  const [orderList, setorderList] = useState([]);
  const [orderListParams, setOrderListParams] = useState(
    `${Moment(currentdateTime).format("Y-M-D")} ${Moment(
      currentdateTime
    ).format("Y-M-D")}`
  );
  const [searchInputValue, setSearchInputValue] = useState();
  const [searchNameInputValue, setSearchNameInputValue] = useState();

  const [ordersFilteredData, setOrdersFilteredData] = useState();

  const [orderLoading, setOrderLoading] = useState(false);

  const [totalAmount, setTotalAmount] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState('');

  console.log('orders: ', orders)

  function createCSV(array) {
    let csvRows = [];
    console.log(array, 'array')

    // Add CSV header row

    let headerRow = [
      "Id",
      "Product Name",
      "Price",
      "Product id",
      "Wishlist item id",
      "Owner Full Name",
      "Buyer User Id",
      "Provider User Id",
      "Creation Date",
      "Wishlist Id",
      "Status",
      "Present Type",
      "Blessing Title",
      "Blessing Text",
      "Delivery Receiver",
      "Courier Receiver",
      "Send By Registered Mail",
      "Send With Courier",
      "Event Type",
      "Is My Bank Buy",
      "Image",
      "Amount"
    ];
    csvRows.push(headerRow.join(","));

    // Iterate over the array of objects and create CSV rows
    array.forEach((item) => {
      console.log('csvRows', item)
      let row = [
        item.order?.id,
        item.productDetails?.name,
        item.productDetails?.price,
        item.productDetails?.productsId,
        item.productDetails?.wishlistItemId,
        item.order?.ownerFullName,
        item.order?.buyerUserId,
        item.order?.providerUserId,
        item.order?.creationDate,
        item.order?.wishlistId,
        item.order?.status,
        item.order?.presentType,
        item.order?.blessingTitle,
        item.order?.blessingText,
        item.order?.deliveryReceiver,
        item.order?.courierReceiver,
        item.order?.sendByRegisteredMail,
        item.order?.sendWithCourier,
        item.order?.eventType,
        item.order?.isMyBankBuy,
        item.order?.image,
        item.order?.amount
      ];
      csvRows.push(row.join(","));
    });

    return csvRows.join("\n");
  }
  function createCSVAdmin(array) {
    let csvRows = [];

    // Add CSV header row
    let headerRow = [
      "Id",
      "Owner Full Name",
      "Buyer User Id",
      "Provider User Id",
      "Creation Date",
      "Wishlist Id",
      "Status",
      "Present Type",
      "Blessing Title",
      "Blessing Text",
      "Delivery Receiver",
      "Courier Receiver",
      "Send By Registered Mail",
      "Send With Courier",
      "Event Type",
      "Is My Bank Buy",
      "Image",
      "Amount",
    ];
    csvRows.push(headerRow.join(","));

    // Iterate over the array of objects and create CSV rows
    array.forEach((item) => {
      let row = [
        item?.id,
        item.ownerFullName,
        item.buyerUserId,
        item.providerUserId,
        item.creationDate,
        item.wishlistId,
        item.status,
        item.presentType,
        item.blessingTitle,
        item.blessingText,
        item.deliveryReceiver,
        item.courierReceiver,
        item.sendByRegisteredMail,
        item.sendWithCourier,
        item.eventType,
        item.isMyBankBuy,
        item.image,
        item.amount,
      ];
      csvRows.push(row.join(","));
    });

    return csvRows.join("\n");
  }


  function downloadCSV() {
    // let csvContent = createCSV(orderList.data); // Generate CSV content
    let csvContent = isAdmin ? createCSVAdmin(filteredOrders) : createCSV(filteredOrders); // Generate CSV content

    let encodedUri = encodeURI(csvContent);

    let link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
    link.setAttribute("download", "orderList.csv");
    link.click();
  }

  const handleFilterDate = (e) => {
    if (!e) return;

    // console.log(e, 'event_date')
    // let months = [
    //   ,
    //   "Jan",
    //   "Feb",
    //   "Mar",
    //   "Apr",
    //   "May",
    //   "Jun",
    //   "Jul",
    //   "Aug",
    //   "Sep",
    //   "Oct",
    //   "Nov",
    //   "Dec",
    // ];
    // let pad = (n) => ("0" + n).slice(-2);
    // console.log('handleFilterDatee', e)
    // let p = String(e[0]).split(" ");
    // let b = String(e[1]).split(" ");

    // const startDate = new Date(`${p[3]}-${pad(months.indexOf(p[1]))}-${p[2]}`);
    // const endDate = new Date(`${b[3]}-${pad(months.indexOf(b[1]))}-${b[2]}`);
    const startDate = new Date(e[0]);
    const endDate = new Date(e[1]);

    startDate.setHours(0, 0, 0, 0); // fix time of the day to midnigh  to be able to filter properly
    endDate.setHours(23, 59, 59, 999); // fix time of the day to midnight to be able to filter properly
console.log(startDate, endDate)
    if (isAdmin) {
      filteredOrders = orders.filter((order) => {
        if (order.creationDate === undefined) return false;
        const orderDate = new Date(order.creationDate);
        
        console.log('orderDate:', orderDate);
        return orderDate >= startDate && orderDate <= endDate;
      });
    } else {
      filteredOrders = orders.filter((order) => {
       // console.log(order, 'order.creationDate');
        if (order.creationDate === undefined) return false;
        const orderDate = new Date(order.creationDate);
        console.log('orderDate:', orderDate);
        return orderDate >= startDate && orderDate <= endDate;
      });
    }

    setOrdersFilteredData(filteredOrders)
    console.log('filteredOrders', filteredOrders, orders)


    setOrderListParams(
      `${Moment(startDate).format("Y-M-D")} ${Moment(endDate).format("Y-M-D")}`
    );
  };

  // const fetchOrderList = async (parms) => {
  //   try {
  //     setOrderLoading(true);
  //     const response = await axios.get(getOderList(parms));
  //     console.log('fetchOrderList', response.data)
  //     // setorderList(response.data);
  //     setOrderLoading(false);
  //   } catch (error) {
  //     setOrderLoading(false);
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   if (!orderListParams) return
  //   fetchOrderList(orderListParams);
  // }, [orderListParams]);

  // Custom comparison function to sort by creationDate in descending order
  function compareDatesDesc(a, b) {
    console.log('compareDatesDesc', a, b)
    const dateA = new Date(a.creationDate);
    const dateB = new Date(b.creationDate);

    if (dateA > dateB) return -1; // Return a negative number to place 'a' before 'b'
    if (dateA < dateB) return 1; // Return a positive number to place 'b' before 'a'
    return 0; // Dates are equal, no change in order
  }


  const fetchOrders = async () => {
    setDataLoading(true);
    try {
      const response = await axios.get(getProvidersReport());
      const data = response.data;
      console.log("jjjjjjj", data)
      // setUserData(data.usersList);
      console.log('fetchOrders', data)
      console.log('fetchAllOrders', data.allOrders)
      if (data.result) {
        setDataLoading(false);
        const totalAmount = data.allOrders?.reduce((acc, order) => {
          return acc + order.amount
        }, 0)
//debugger
        setTotalAmount(totalAmount)
        console.log('set orders', "fetchAllOrders")
       const sortedOrders = data.allOrders?.slice().sort(compareDatesDesc);

        setOrders(data.providerOrdersSummary)       
      } else {
        alert('Something went wrong')
      }

    } catch (error) {
      console.error(error);
      setDataLoading(false);
    }
  };

  const fetchAllOrders = async () => {
    try {
      const payload = userId; // userId as plain string
      console.log("Request payload:", payload);
  
      const response = await axios.post(getAllCompleteProvidersOrders(), payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      const data = response.data;
      console.log('fetchAllOrders', data);
  
      if (data.result) {
        setDataLoading(false);
        const totalAmount = data.allOrders?.reduce((acc, order) => acc + order.amount, 0);
        setTotalAmount(totalAmount);
        const sortedOrders = data.allOrders?.slice().sort(compareDatesDesc);
        setOrders(sortedOrders);
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Error fetching orders:', error.response ? error.response.data : error.message);
    }
  };
  
 const setFilterdValueForName = () => {
  if(isAdmin){
     filterResult()
  }
  
 


  }
 
const filterResult = ()=>{
  debugger;
  const filter  = orders?.filter(order =>
  (!searchNameInputValue || order.providerName.toLowerCase().includes(searchNameInputValue?.toLowerCase())  )
    

) ;
setOrdersFilteredData(filter);
}

  const setFilterdValue = () => {
    if (isAdmin) {
      const filtered1 = searchInputValue ? orders?.filter(order =>
        order.status == searchInputValue
      ) : orders;
      console.log("yyyyyyyyyyyyyyyyyyyyyyyy",orders)

      filterResult()

    }
    else {
      const filtered2 = searchInputValue ? orders?.filter(order =>
        order.status == searchInputValue
      ) : orders;
      console.log("yyyyyyyyyyyyyyyyyyyyyyyy",orders)
      setOrdersFilteredData(filtered2);


    }
    console.log("!!!!! ", orders)

  }

  const toggleOrderStatus = async (e, id) => {
    const status = e.target.value;
    // setSelectedStatus(status);
    const orderId = id;
    const params = { status, orderId }
    console.log('toggleOrderStatus', status, id)
    try {

      const response = await axios.post(updateOrderStatus(), params);


      const data = response.data;
      e.target.value = status;
      console.log('toggleOrderStatus', data)
      

      if (data.result) {


          setOrders(od => (
            od.map(order => {
              if (order?.id == orderId) {
                return { ...order, status };
              } else {
                return order;
              }
            })
          ))
        

       
      }
    } catch (error) {
      // Handle the error
      console.error(error);
    }

  }
  useEffect(() => {
    setSearchInputValue(""); 
  }, []);

 
  useEffect(() => {
    setFilterdValue()
  }, [searchInputValue, orders, ])
  useEffect(() => {
    setFilterdValueForName()
  }, [searchNameInputValue])

  useEffect(() => {
    // if (!dataLoading) {
    if (isAdmin) {
      console.log('isAdmin', isAdmin)      
      fetchOrders()

    } else {
      console.log('isAdmin', isAdmin) 
           fetchAllOrders()

    }
    // }
    return () => {

    }
  }, [isAdmin])
  useEffect(()=>{
    console.log("(((((((((((((((, ", ordersFilteredData)

  },[ordersFilteredData])


  return (
    <>
      <div className={Styles.containerUser}>
     {/*   <div className={Styles.headingUser}>
          <h1>
            {langData[langConfig.userType][langConfig.lang].tab4.export.title}
          </h1>
  </div>*/}
  {isAdmin &&

<div className='col-3 mt-5' style={{ borderColor: 'lightgrey'}}>
  <TextField
    label="חפש שם ספק"
    variant="outlined"
    onChange={(event) => {
      setSearchNameInputValue(event.target.value);
    }}
  />
</div>}
  {!isAdmin &&
        <div className="col-md-12">
          <div className={`${Styles.cardHeight} card p-5 mt-2 mt-md-0`}>
            <div className={Styles.headingUser}>
              <h1>
                {/*{langData[langConfig.userType][langConfig.lang].tab4.title}*/}
              </h1>
              <h2>סכום כל ההזמנות
                {totalAmount ? ' - ' + totalAmount.toLocaleString() + " ₪" : 0 + " ₪"}
              </h2>
            </div>
            <div className='row'>

  <div className='col-3 mt-5' style={{ borderColor: 'gray'}}>
  <Form.Select
  aria-label="סטטוס"
  onChange={(event) => {
    setSearchInputValue(event.target.value);
  }}  value={searchInputValue}
  style={{
    width: '210px',
    height: '58px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'sans-serif',
    fontWeight: '400',
    fontSize: '1rem',
  }}
>
  <option
    value=""
    disabled
    hidden
    style={{
      color: 'rgba(0, 0, 0, 0.6)',
      fontFamily: 'sans-serif',
      fontWeight: '400',
      fontSize: '1rem',
      paddingRight: '20px', // Adjust the padding as needed
    }}
  >
    בחר סטטוס הזמנה
  </option>
  <option></option>
  {orderStatuses &&
    orderStatuses.map((option) => (
      <option key={option} value={option}>
    
        {option === 'delivered' && 'נמסרה'}
        {option == 'paidProvider' && 'שולם לספק'}

      </option>
    ))}
</Form.Select>


</div>

            <div className="col-3" style={{marginTop: '6vh'}}>
              <DateRangePicker
                className=" my-3"
                //style={{direction: "rtl"}}
               // appearance="default"
                format="dd-MM-yyyy"
                onChange={handleFilterDate}
                ref={dateRangeRef}
             
                />

            { /* <div>
                <button
                  onClick={() => downloadCSV()}
                  className={`${Styles.btnStyle} my-3 ${filteredOrders?.length === 0 && Styles.opacity
                    }`}
                  disabled={filteredOrders?.length === 0}
                >
                  {orderLoading ? (
                    <div
                      className="spinner-border text-light"
                      style={{
                        fontSize: "10px !important",
                        margin: "0px 30px",
                        height: "1rem !important",
                        width: "1rem !important",
                      }}
                      role="status"
                    ></div>
                  ) : (langData[langConfig.userType][langConfig.lang].tab4.export.exportBtn)}
                </button>
              </div>*/}
            </div>
          </div>
          </div>

        </div>}
        <div className={Styles.filterBox}>
          <div className="d-flex justify-content-center align-items-center">

            {/* <label htmlFor="switch" className={Styles.switchLabel}>
              Enable
            </label> */}
          </div>
        </div>





        {isAdmin ? (
          <ReportsGeneral
            data={ordersFilteredData}
            tableLoading={dataLoading}
            heading={heading}
            orderStatuses={orderStatuses}
            toggleOrderStatus={toggleOrderStatus}
            langData={langData}
            langConfig={langConfig}
          />
        ) : (
         
          <Reports
            data={ordersFilteredData}
            tableLoading={dataLoading}
            heading={heading}
            orderStatuses={orderStatuses}
            toggleOrderStatus={toggleOrderStatus}
            langData={langData}
            langConfig={langConfig}
            selectedStatus={selectedStatus}
            userId={userId}
          />

        )}
   

      </div>
    </>
  )
}
