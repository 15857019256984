import React, { useState, useEffect, useRef } from 'react';
import Styles from './Orders.module.css';
import { getProviderOrders, updateOrderStatus, getOderList, getAllOrders } from '../../../config/api';
import TableOrdersAdmin from '../../components/Tables/TableOrdersAdmin';
import TableOrdersProvider from '../../components/Tables/TableOrdersProvider';
import axios from 'axios';
import Moment from 'moment';
import { DateRangePicker } from 'rsuite';
import { Form, Button } from 'react-bootstrap';
import TextField from '@mui/material/TextField';

const orderStatuses = ["processing", "shipped", "delivered", "paidProvider"];

function Orders({ userId, isAdmin, langConfig, langData }) {
  const currentdateTime = Moment();
  const dateRangeRef = useRef(null);
  const [heading, setHeading] = useState('Orders');
  const [dataLoading, setDataLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [orderListParams, setOrderListParams] = useState(`${Moment(currentdateTime).format("Y-M-D")} ${Moment(currentdateTime).format("Y-M-D")}`);
  const [searchInputValue, setSearchInputValue] = useState("processing");
  const [searchNameInputValue, setSearchNameInputValue] = useState('');
  const [ordersFilteredData, setOrdersFilteredData] = useState([]);
  const [orderLoading, setOrderLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');

  useEffect(() => {
    const fetchOrders = async () => {
      setDataLoading(true);
      try {
        const response = await axios.get(getProviderOrders(userId));
        const data = response.data;
        if (data.result) {
          setDataLoading(false);
          const totalAmount = data.allOrders?.reduce((acc, order) => acc + order.amount, 0);
          setTotalAmount(totalAmount);
          const sortedOrders = data.allOrders?.slice().sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
          setOrders(sortedOrders);
        } else {
          alert('Something went wrong');
        }
      } catch (error) {
        console.error(error);
        setDataLoading(false);
      }
    };

    const fetchAllOrders = async () => {
      setDataLoading(true);
      try {
        const response = await axios.post(getAllOrders());
        const data = response.data;
        if (data.result) {
          setDataLoading(false);
          const totalAmount = data.allOrders?.reduce((acc, order) => acc + order.amount, 0);
          setTotalAmount(totalAmount);
          const sortedOrders = data.allOrders?.slice().sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
          setOrders(sortedOrders);
        } else {
          alert('Something went wrong');
        }
      } catch (error) {
        console.error(error);
        setDataLoading(false);
      }
    };

    if (isAdmin) {
      fetchAllOrders();
    } else {
      fetchOrders();
    }
  }, [isAdmin, userId]);

  useEffect(() => {
    const filterOrders = () => {
      const filtered = orders?.filter(order =>
        (!searchNameInputValue || order.providerFullName.toLowerCase().includes(searchNameInputValue.toLowerCase())) &&
        (order.status === searchInputValue || !searchInputValue)
      );
      setOrdersFilteredData(filtered);
    };
    filterOrders();
  }, [searchInputValue, searchNameInputValue, orders]);

  const handleFilterDate = (e) => {
    if (!e) return;
    const startDate = new Date(e[0]);
    const endDate = new Date(e[1]);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const filtered = orders?.filter(order => {
      if (!order.creationDate) return false;
      const orderDate = new Date(order.creationDate);
      return orderDate >= startDate && orderDate <= endDate;
    });

    setOrdersFilteredData(filtered);
    setOrderListParams(`${Moment(startDate).format("Y-M-D")} ${Moment(endDate).format("Y-M-D")}`);
  };

  const toggleOrderStatus = async (e, id) => {
    const status = e.target.value;
    const orderId = id;
    const params = { status, orderId };
    try {
      const response = await axios.post(updateOrderStatus(), params);
      const data = response.data;
      if (data.result) {
        setOrders(prevOrders =>
          prevOrders.map(order => (order?.id === orderId ? { ...order, status } : order))
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={Styles.containerUser}>
      <div className="col-md-12">
        <div className={`${Styles.cardHeight} card p-5 mt-2 mt-md-0`}>
          <div className={Styles.headingUser}>
            <h1></h1>
            <h2>סכום כל ההזמנות {totalAmount ? ' - ' + totalAmount.toLocaleString() + " ₪" : 0 + " ₪"}</h2>
          </div>
          <div className='row'>
            {isAdmin && (
              <div className='col-3 mt-5' style={{ borderColor: 'lightgrey' }}>
                <TextField
                  label="חפש שם ספק"
                  variant="outlined"
                  onChange={(event) => setSearchNameInputValue(event.target.value)}
                />
              </div>
            )}
            <div className='col-3 mt-5' style={{ borderColor: 'gray' }}>
              <Form.Select
                aria-label="סטטוס"
                onChange={(event) => setSearchInputValue(event.target.value)}
                value={searchInputValue}
                style={{
                  width: '210px',
                  height: '58px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontFamily: 'sans-serif',
                  fontWeight: '400',
                  fontSize: '1rem',
                }}
              >
                <option value="" disabled hidden style={{ color: 'rgba(0, 0, 0, 0.6)', fontFamily: 'sans-serif', fontWeight: '400', fontSize: '1rem', paddingRight: '20px' }}>
                  בחר סטטוס הזמנה
                </option>
                <option></option>
                {orderStatuses.map((option) => (
                  <option key={option} value={option}>
                    {option === 'processing' && 'חדשה'}
                    {option === 'pending' && 'בהמתנה'}
                    {option === 'shipped' && 'נשלחה'}
                    {option === 'delivered' && 'התקבלה'}
                    {option === 'paidProvider' && 'שולם לספק'}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-3" style={{ marginTop: '6vh' }}>
              <DateRangePicker className="my-3" format="dd-MM-yyyy" onChange={handleFilterDate} ref={dateRangeRef} />
            </div>
          </div>
        </div>
      </div>
      {isAdmin ? (
        <TableOrdersAdmin
          data={ordersFilteredData}
          tableLoading={dataLoading}
          heading={heading}
          orderStatuses={orderStatuses}
          toggleOrderStatus={toggleOrderStatus}
          langData={langData}
          langConfig={langConfig}
        />
      ) : (
        <TableOrdersProvider
          data={ordersFilteredData}
          tableLoading={dataLoading}
          heading={heading}
          orderStatuses={orderStatuses}
          toggleOrderStatus={toggleOrderStatus}
          langData={langData}
          langConfig={langConfig}
          selectedStatus={selectedStatus}
        />
      )}
    </div>
  );
}

export default Orders;
