import React, { useState,useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Styles from '../User-Data/User.module.css'
import Table from '../../components/Tables/table';
import axios from 'axios';
import { getProductCategory } from '../../../config/api';

function ProductList() {

  const location = useLocation();
  const navigate = useNavigate();
  const pathName = location.pathname;
  // const dateRangeRef = useRef(null);
  // const currentdateTime = Moment();

  const [heading, setHeading] = useState(
    pathName === "/provider" ? "Providers" : pathName === '/' ? "Users" : pathName === '/product-list' ? 'Products list' : ''
  );


  const [userData, setUserData] = useState();

  const [requestParams, setRequestParams] = useState({
    page: 3,
    pageSize: 0,
    totalRecords: 1,
    sortIndex: -1,
    search: "",
    sortBy: "",
  });
  const [dataLoading, setDataLoading] = useState(false);

  const fetchData = async () => {
    try {
      setDataLoading(true);
      const response = await axios.post(getProductCategory(), requestParams);
      const data = response.data;
      console.log('getProductCategory', data)
      // setUserData(userListById);
      console.log(userData);
      setDataLoading(false);
    } catch (error) {
      console.error('fetch_users', error);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    fetchData()
    return () => {

    }
  }, [])


  return (
    <div className={Styles.containerUser}>
      <div className={Styles.headingUser}>
        <h1>{heading} Data</h1>
      </div>
      <div className={Styles.filterBox}>

        {/* <div className="d-flex justify-content-center align-items-center">
          <label className={Styles.switch}>
            <input
              type="checkbox"
              name="switch"
              value={enable}
              checked={enable}
              onChange={() => {
                setRequestParams({
                  ...requestParams,
                  enableDisableUsers: !requestParams.enableDisableUsers,
                });
                setEnable(!enable);
              }}
            />
            <span className={`${Styles.slider} ${Styles.round}`}></span>
          </label>
          <label htmlFor="switch" className={Styles.switchLabel}>
            Enable
          </label>
        </div> */}
      </div>
      <Table
        data={userData}
        tableLoading={dataLoading}
        heading={heading}
      // type={tableType}
      // updateUser={updateUser}
      />
    </div>
  )
}

export default ProductList