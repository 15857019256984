import React, { useEffect, useState } from "react";
import Styles from "../../Categories/Categories.module.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { Button } from 'react-bootstrap'
import { DOMAIN } from '../../../../constants/envUrl'
import { useNavigate } from "react-router-dom";
import {
  UpdateProviderCategoriesListOptions,
  getAllCategories,
  getProviderCategoriesList,
  getProviderCategoriesListOptions,
  addMainCategoriesToProductCategory,
  gellAllCategoriesByProductCat,
  updateProductCategory,
  uploadFile,
  createProductCategory
} from "../../../../config/api";
import { useParams } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { Style } from "@mui/icons-material";
import noPeopleImg from '../../../assets/Images/2.png'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Categories = ({ langConfig, langData }) => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [categoriesOptionsList, setcategoriesOptionsList] = useState([]);
  const [allCatByProduct, setAllCatByProduct] = useState([])
  const [editingElement, setEditingElement] = useState(null)
  const [editableValue, setEditableValue] = useState('')
  const [newProductCat, setNewProductCat] = useState({
    // id: null,
    name: "",
    imageLink: "",
    iconLink: "",
    description: ""
  })

  const [isAddCategory, setIsAddCategory] = useState(false)
  const navigate = useNavigate();

  const formNewProductCat = async (key, event) => {

    if (key === 'imageLink' || key === 'iconLink') {

      const file = event.target.files[0]

      try {
        const formData = new FormData();
        formData.append('image', file); // Assuming the file is an instance of File or Blob

        const response = await axios.post(uploadFile(), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status !== 200) return
        const data = response.data;
        // setNewProductCat(prevProdCat => ({ ...prevProdCat, [key]: `https://yyy.codersuccess.com/server-images/${data}` }))
        setNewProductCat(prevProdCat => ({ ...prevProdCat, [key]: `/server-images/${data}` }))

        // `https://yyy.codersuccess.com/server-images/${data}`
        console.log("formNewProductCat: ", data)
      } catch (err) {
        console.log(err)
      }

    } else {
      setNewProductCat(prevProdCat => ({ ...prevProdCat, [key]: event.target.value }))
    }
  }

  const createNewProductCat = async () => {

    const response = await axios.post(createProductCategory(), newProductCat)
    setIsAddCategory(false)
    fetchAllCatByProduct()
    console.log('createNewProductCat', response.data)
  }

  console.log('allCatByProduct: ', allCatByProduct)


  const editElmentHandler = (id) => {
    // const input = document.querySelector(`#input-${id}`)
    // input.focus()
    setEditingElement(id)
    console.log(id)
  }

  const uploadImage = async (event, obj, key) => {

    const file = event.target.files[0]

    try {
      const formData = new FormData();
      formData.append('image', file); // Assuming the file is an instance of File or Blob

      const response = await axios.post(uploadFile(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const data = response.data;
      if (response.status !== 200) return
      setEditableValue(`/server-images/${data}`)

      const { description, categoryImage, categoryIcon, niceName, id } = obj;

      const updatedObject = {
        id: id,
        name: niceName,
        imageLink: key === 'imageLink' ? `/server-images/${data}` : categoryImage,
        iconLink: key === 'iconLink' ? `/server-images/${data}` : categoryIcon,
        description: description
      }


      const responseUpdate = await axios.post(updateProductCategory(), updatedObject)

      console.log('response.result', response)
      if (responseUpdate.data.result) {
        setEditingElement(null)
        setEditableValue('')
        fetchAllCatByProduct()

      }

      return data;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const deleteCategory = async (id) => {
    //Need to add real endpoint when have one
    // const response = await axios.post(deleteCategoryEndp(), id)
    // if (response.result) {
    //   alert()
    //   fetchAllCatByProduct()
    // } else {
    //   alert("Something went wrong while deleting category")
    // }

  }

  const updateProductCat = async (obj, key) => {
    const { description, categoryImage, categoryIcon, niceName, id } = obj;

    const updatedObject = {
      id: id,
      name: key === 'name' ? editableValue : niceName,
      imageLink: key === 'imageLink' ? editableValue : categoryImage,
      iconLink: key === 'iconLink' ? editableValue : categoryIcon,
      description: key === 'description' ? editableValue : description
    }
    // const updatedObject = {
    //   id: id,
    //   name: key === 'name' ? editableValue : niceName,
    //   imageLink: key === 'imageLink' ? editableValue : categoryImage,
    //   iconLink: key === 'iconLink' ? editableValue : categoryIcon,
    //   description: key === 'description' ? editableValue : description
    // }

    console.log('updateProductCat', obj, key, updatedObject)

    const response = await axios.post(updateProductCategory(), updatedObject)

    console.log('response.result', response)
    if (response.data.result) {
      setEditingElement(null)
      setEditableValue('')
      fetchAllCatByProduct()

    }

    // const newObj = {...obj, [key] : editableValue}
    console.log('updateProductCat: ', updatedObject)
  }

  const fetchAllCatByProduct = async () => {
    const response = await axios.post(gellAllCategoriesByProductCat());
    if (response.status == 200) {
      const { data } = response;
      console.log('fetchAllCatByProduct: ', response.data)
      setAllCatByProduct(data)
    }


  }
  console.log('allCatByProduct', allCatByProduct)

  const handleCheckOption = async (event, indexMain, indexInner, dropId, listId) => {
    console.log('handleCheckOption:event, indexMain,indexInner, dropId, listId', event.target.checked, indexMain, indexInner, dropId, listId)


    const getCheckedCategoryIdsForIndex = (updatedCatByProduct, indexMain) => {
      const checkedCategoryIds = [];

      const mainCategoryList = updatedCatByProduct[indexMain].mainCategoryList;

      for (const category of mainCategoryList) {
        if (category.checked) {
          checkedCategoryIds.push(category.category.id);
        }
      }

      return checkedCategoryIds;
    };

    const updatedCatByProduct = [...allCatByProduct]; // Shallow copy of the main array

    updatedCatByProduct[indexMain] = {
      ...updatedCatByProduct[indexMain],
      mainCategoryList: updatedCatByProduct[indexMain].mainCategoryList.map(
        (item, innerIndex) => {
          if (innerIndex === indexInner) {
            return {
              ...item,
              checked: event.target.checked
            };
          }
          return item;
        }
      )
    };
    const checkedCategoryIds = getCheckedCategoryIdsForIndex(
      updatedCatByProduct,
      indexMain
    );
    console.log(`Checked Category IDs for index ${indexMain}:`, checkedCategoryIds);
    setAllCatByProduct(updatedCatByProduct);
    const params = {
      categoryProductId: listId,
      mainCategoriesList: [...checkedCategoryIds]
    };

    try {
      const response = await axios.post(addMainCategoriesToProductCategory(), params, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log(response.data)
      if (response.data.result) {
        // fetchCategoriesList()
        fetchAllCatByProduct()
      }

      // console.log('Response:', response.data);
      // Handle the response here
    } catch (error) {
      console.error('Error: addMainCategoriesToProductCategory failed', error);
      // Handle the error here
    }
  };


  const fetchCategoriesList = async () => {
    try {
      setCategoriesLoading(true);
      // const response = await axios.get(getProviderCategoriesList(id));
      const response = await axios.get(getProviderCategoriesListOptions());
      const data = response.data;
      setCategoriesList(data.data);
      // console.log("categoriesList", data.data);
      setCategoriesLoading(false);
    } catch (error) {
      console.error(error);
      setCategoriesLoading(false);
    }
  };


  const UpdateCategoriesListOptions = async () => {
    try {
      // console.log("categoriesList send to server", categoriesList);
      setCategoriesLoading(true);
      const response = await axios.post(
        UpdateProviderCategoriesListOptions(),
        categoriesList
      );
      setCategoriesLoading(false);
      await fetchCategoriesList();
    } catch (error) {
      console.error(error);
      setCategoriesLoading(false);
    }
  };

  useEffect(() => {
    // fetchCategoriesListOptions();
    fetchAllCatByProduct()
  }, []);

  const addCategory =()=>{
navigate('/add-product-category')
  }

  return (
    <div className={Styles.containerUser}>
      {/*<div className={Styles.headingUser}>
        <h1>
          {langData[langConfig.userType][langConfig.lang].tab2.productCategories.subTabName}
        </h1>
      </div>*/}

      {/*<div className={Styles.filterBox}>
        <div className="d-flex justify-content-end align-items-center">
          <button
            className={`${Styles.btnStyle}`}
            onClick={() => {
              UpdateCategoriesListOptions();
            }}
          >
            {langData[langConfig.userType][langConfig.lang].tab2.productCategories.updateBtn}
          </button>
        </div>
      </div>*/}
      <div style={{display: 'flex', justifyContent: 'left'}}>
                   
                          <button
                            onClick={() => {
                              addCategory()
                           
                            }}
                            className={`${Styles.btnStyle}`}
                          >
                           הוסף קטגוריה משנית
                          </button>
                

                      </div>
      <div className="card mt-5">
        <div className="card-header">
          <h2 className="card-title">
קטגוריות משניות
          </h2>
        </div>
        <div className="card-body">
          <div className={Styles.tableResponsive}>
            <table className={`${Styles.table} ${Styles.tableStriped}`}>
              <thead>
                <tr>
                  <th className={`${Styles.horizantal}`}>
                    {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.mainCat}
                  </th>
                  <th>
                    {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.catName}
                  </th>
                  <th>
                    {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.catDesc}
                  </th>
                  <th>
                    {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.catIcon}
                  </th>
                  <th>
                    {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.catImage}
                  </th>
                  {/* <th> */}
                    {/* {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.catDate} */}
                  {/* </th> */}
                  <th></th>
                </tr>
              </thead>
              {!categoriesLoading ? (
                <tbody>
                  {allCatByProduct?.length > 0 ? (
                    allCatByProduct?.map((itemList, index) => (
                      <tr key={index}>
                        <td>
                          <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel
                              id={`demo-multiple-checkbox-label-${index}`}
                            >
                              קטגוריות
                            </InputLabel>
                            <Select
                              labelId={`demo-multiple-checkbox-label-${index}`}
                              id={`demo-multiple-checkbox-${index}`}
                              multiple
                              value={allCatByProduct}
                              // onChange={(event) =>
                              //   handleOptionChange(event, index, itemList.productCategory.id)
                              // }
                              input={<OutlinedInput label="Categories" />}
                              renderValue={(selected) => {
                                console.log(selected[index], 'selected ' + index)
                                return selected[index]?.mainCategoryList?.filter((item, index) => item.checked).map(cat => cat.category.categoryAdminName).join(', ')
                              }
                              }
                              MenuProps={MenuProps}
                            >
                              {itemList.mainCategoryList?.map((option, indexInner) => (

                                <MenuItem key={indexInner}
                                  value={option.category?.categoryName}
                                >
                                  <Checkbox
                                    checked={option.checked}
                                    onChange={(event) => handleCheckOption(event, index, indexInner, option.category.id, itemList.productCategory.id)}
                                  />
                                  <ListItemText primary={option.category?.categoryAdminName} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </td>
                        <td>

                          {editingElement == `${index}0` ? (
                            <>
                              <input
                                onChange={(e) => setEditableValue(e.target.value)}
                                id={`input-${index}0`} type="text" defaultValue={itemList.productCategory.niceName} />
                              <button onClick={() => updateProductCat(itemList.productCategory, 'name')}>Save</button>
                            </>
                          ) : (
                            <>
                              <span>
                                {itemList.productCategory.niceName}
                              </span>
                              <EditIcon onClick={() => editElmentHandler(`${index}0`)} />
                            </>
                          )}
                        </td>
                        <td>

                          {editingElement == `${index}1` ? (
                            <>
                              <input
                                onChange={(e) => setEditableValue(e.target.value)}
                                id={`input-${index}1`} type="text" defaultValue={itemList.productCategory.description} />
                              <button onClick={() => updateProductCat(itemList.productCategory, 'description')}>Save</button>
                            </>
                          ) : (
                            <>
                              <span>
                                {itemList.productCategory.description}
                              </span>
                              <EditIcon onClick={() => editElmentHandler(`${index}1`)} />
                            </>
                          )}


                        </td>
                        <td>

                          <img className={`${Styles.tableImage}`} src={`${DOMAIN}${itemList.productCategory.categoryIcon}`} alt="" />

                          <label>
                            <input
                              style={{ width: 0, position: "absolute", left: "100%" }}
                              type="file"
                              accept="image/*"
                              onChange={(e) => uploadImage(e, itemList.productCategory, 'iconLink')}
                              id={`input-${index}2`}
                            />
                            <EditIcon onClick={() => editElmentHandler(`${index}2`)} />
                          </label>

                        </td>
                        <td>
                          <img className={`${Styles.tableImage} img-people`} src={`${DOMAIN}${itemList.productCategory.categoryImage}`} alt="" />
                          <img className={`${Styles.tableImage} img-no-people`} src={noPeopleImg} alt="" />

                          <label>
                            <input
                              style={{ width: 0, position: "absolute", left: "100%" }}
                              type="file"
                              accept="image/*"
                              onChange={(e) => uploadImage(e, itemList.productCategory, 'imageLink')}
                              id={`input-${index}2`}
                            />
                            <EditIcon onClick={() => editElmentHandler(`${index}2`)} />
                          </label>

                        </td>
                        <td>
                            <Button
                              onClick={() => navigate(`/edit-product-category/${itemList.productCategory.id}`)}
                            >
                              עריכה
                            </Button>
                          </td>
                     
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={"3"} className="text-center py-5">
                        No data
                      </td>
                    </tr>
                  )}

                  {isAddCategory && (
                    <tr
                    // key={index}
                    >
                      <td>

                      </td>
                      <td>

                        <input
                          onChange={(e) => formNewProductCat('name', e)}
                          type="text" />

                      </td>
                      <td>
                        <input
                          onChange={(e) => formNewProductCat('description', e)}
                          // id={`input-${index}1`} 
                          type="text"
                        />
                      </td>
                      <td>
                        {newProductCat.iconLink ? (
                          <img className={`${Styles.tableImage}`} src={`${DOMAIN}${newProductCat.iconLink}`} alt="" />

                        ) : (
                          <label>
                            <input
                              style={{ width: 0, position: "absolute", left: "100%" }}
                              type="file"
                              accept="image/*"
                              onChange={(e) => formNewProductCat('iconLink', e)}
                            // id={`input-${index}2`}
                            />
                            <span
                              style={{ display: "block", width: "max-content" }}
                              className={`${Styles.btnStyle}`}

                            >
                              {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.uploadIcon}
                            </span>
                          </label>
                        )}


                      </td>
                      <td>
                        {
                          newProductCat.imageLink ? (
                            <>

                              <img className={`${Styles.tableImage} img-people`} src={`${DOMAIN}${newProductCat.imageLink}`} alt="" />
                              <img className={`${Styles.tableImage} img-no-people`} src={noPeopleImg} alt="" />
                            </>

                          ) : (
                            <label>
                              <input
                                style={{ width: 0, position: "absolute", left: "100%" }}
                                type="file"
                                accept="image/*"
                                onChange={(e) => formNewProductCat('imageLink', e)}

                              // id={`input-${index}2`}
                              />
                              <span
                                style={{ display: "block", width: "max-content" }}
                                className={`${Styles.btnStyle}`}

                              >
                                {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.uploadImage}
                              </span>
                            </label>
                          )
                        }

                      </td>
                      {/* <td>
                      </td> */}
                      <td>
                        <button
                          onClick={createNewProductCat}
                          className={`${Styles.btnStyle}`}
                        >
                          {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.saveCategory}
                        </button>
                      </td>
                    </tr>)}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    {/* <td></td> */}
                    <td>
             
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan={"3"}
                      className="text-center"
                      style={{
                        height: "200px",
                      }}
                    >
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      ></div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Categories;
