import React from "react";
import Styles from "./Categories.module.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 9.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

const CategoryRow = React.memo(
  ({
    item,
    index,
    isAdmin,
    setChangeInCategories,
    setSavedCategories,
    categoriesList,
    setCategoriesList,
    openCategoryProducts,
    handleScroll,
    selectRef,
    searchInputRef,
    performFiltering,
    combinedHandler,
    visibleOptionsCount,
  }) => {
    return (
      <tr>
        <td>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id={`demo-multiple-checkbox-label-${index}`}>
              קטגוריות
            </InputLabel>
            <Select
              labelId={`demo-multiple-checkbox-label-${index}`}
              id={`demo-multiple-checkbox-${index}`}
              multiple
              value={item.systemCategories
                .filter((cat) => cat.isChecked)
                .map((cat) => cat.niceName)}
              input={<OutlinedInput label="Categories" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                ...MenuProps,
                PaperProps: {
                  ...MenuProps.PaperProps,
                  onScroll: handleScroll,
                },
              }}
              ref={selectRef}
            >
              {/* Sticky search input */}
              <MenuItem className={Styles.stickySearch}>
                <OutlinedInput
                  placeholder="Enter לחיפוש"
                  inputRef={searchInputRef}
                  className={Styles.searchInput}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    if (e.key === "Enter") {
                      performFiltering();
                    }
                  }}
                />
              </MenuItem>

              {/* Render options */}
              {item.systemCategories
                .sort((a, b) =>
                  a.isChecked === b.isChecked ? 0 : a.isChecked ? -1 : 1
                )
                .slice(0, visibleOptionsCount)
                .map((option, indexInner) => (
                  <MenuItem
                    key={indexInner}
                    value={option.niceName}
                    className={
                      !option.isChecked ? Styles.uncheckedCategory : ""
                    }
                    onClick={(event) => event.stopPropagation()}
                  >
                    <Checkbox
                      checked={option.isChecked}
                      onChange={(event) => {
                        combinedHandler(event, option, index);
                        performFiltering(); // Re-apply filtering after selection
                      }}
                    />
                    <ListItemText primary={option.adminName} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </td>
        <td>{item.niceName}</td>
        <td>{item.systemCategory}</td>
        {!isAdmin && (
          <td>
            <button
              style={{ margin: "10px", width: "85px" }}
              className="btn btn-primary ml-1 actionbutton"
              onClick={() =>
                openCategoryProducts(item.providerCategoryId, item.niceName)
              }
            >
              Products
            </button>
          </td>
        )}
      </tr>
    );
  }
);


export default CategoryRow;
