import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, TextField, Typography, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteIcon from '@mui/icons-material/Delete';
import { uploadFile, UpdateCreateProduct, getProviderCategoriesListOptions, getProvidersNames } from '../../../config/api';
import { DOMAIN } from '../../../constants/envUrl';
import Styles from '../../components/Tables/table.module.css';
import Styles2 from './edit-product.module.css';

const initialProductTemplate = {
  name: "",
  price: null,
  categories: null,
  categoryId: null,
  description: "",
  images: "",
  creationDate: "",
  providerOriginalCategoriesId: null,
  tax: null,
  warranty: null,
  manufacturer: null,
  deliveryTime: null,
  shipmentCost: 0,
  productUrl: null,
  model: null,
  catalogNumber: null,
  productCode: null,
  currency: null,
  variations: [],
  deliveryTypes: [],
  discountPrice: null,
  expandedDescription: [],
  providerId: ""
};

function stringifyArrays(obj) {
  const newObj = { ...obj };

  function recurse(current) {
    for (const key in current) {
      if (Array.isArray(current[key])) {
        current[key] = JSON.stringify(current[key]);
      } else if (typeof current[key] === 'object' && current[key] !== null) {
        recurse(current[key]);
      }
    }
  }

  recurse(newObj);
  return newObj;
}

function AddProduct({ userId, langData, langConfig }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [productData, setProductData] = useState(initialProductTemplate);
  const [providerNames, setProviderNames] = useState([]);
  const [prodValidation, setProdValidation] = useState({
    name: true,
    price: true,
    categoryId: true,
    description: true,
    images: true,
  });
  const [categoriesList, setCategoriesList] = useState([]);

  const fetchProviderOptions = async () => {
    try {
      const response = await axios.post(getProvidersNames(), {});
      const options = response.data.providersNames.map(provider => ({
        value: provider.providerId,
        label: provider.providerName,
      }));
      setProviderNames(options);
    } catch (error) {
      console.error('Error fetching provider options:', error);
    }
  };

  useEffect(() => {
    fetchProviderOptions();
  }, []);

  const handleInputEdit = (e, id, mainKeyName, name) => {
    let { value } = e.target;
    if (Array.isArray(productData[mainKeyName])) {
      const updatedProduct = productData[mainKeyName].map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [name]: value,
          };
        } else {
          return item;
        }
      });

      setProductData({
        ...productData,
        [mainKeyName]: updatedProduct,
      });
    } else {
      const updatedValue = productData[mainKeyName] = value;
      setProductData({ ...productData, [mainKeyName]: updatedValue });
      setProdValidation({ ...prodValidation, [mainKeyName]: value.length > 0 });
    }
  };

  const addImage = async (event) => {
    const formData = new FormData();
    const file = event.target.files[0];
    formData.append('image', file);
    try {
      const response = await axios.post(uploadFile(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status !== 200) return;
      const data = response.data;
      const updatedImagesArr = productData.images.replace(/[\[\]]/g, '') + `,/images/${data}`;
      setProductData({ ...productData, images: updatedImagesArr });
      setProdValidation({ ...prodValidation, images: true });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteImage = (index) => {
    const confirmed = window.confirm("Are you sure you want to remove this image?");
    if (confirmed) {
      const updatedImagesArr = productData.images.split(',').filter((item, i) => i !== index).join(',');
      setProductData({ ...productData, images: updatedImagesArr });
    }
  };

  const addItem = (mainKeyName) => {
    let updatedProduct;

    if (mainKeyName === 'variations') {
      updatedProduct = {
        id: productData[mainKeyName].length,
        name: '',
        values: '',
      };
    } else if (mainKeyName === 'deliveryTypes') {
      updatedProduct = {
        id: productData[mainKeyName].length,
        name: '',
        price: '',
      };
    } else if (mainKeyName === 'expandedDescription') {
      updatedProduct = {
        id: productData[mainKeyName].length,
        title: '',
        text: '',
      };
    }

    setProductData({
      ...productData,
      [mainKeyName]: [...productData[mainKeyName], updatedProduct],
    });
  };

  const removeItem = (mainKeyName, id) => {
    const confirmed = window.confirm("Are you sure you want to remove this item?");
    if (confirmed) {
      const updatedProduct = productData[mainKeyName].filter((item) => item.id !== id);
      setProductData({ ...productData, [mainKeyName]: updatedProduct });
    }
  };

  const fetchCategoriesList = async () => {
    try {
      const response = await axios.get(getProviderCategoriesListOptions());
      const data = response.data;
      setCategoriesList(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCategoryFilter = (event, categoryId) => {
    debugger;
    setProductData({ ...productData, categoryId });
    setProdValidation({ ...prodValidation, categoryId: true });
  };

  const handleProvideFilter = (event, providerId) => {
    setProductData({ ...productData, providerId });
    setProdValidation({ ...prodValidation, providerId: true });
  };

  function formatDate(date, format) {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-indexed
    const year = date.getFullYear();

    format = format.replace('DD', String(day).padStart(2, '0'));
    format = format.replace('MM', String(month).padStart(2, '0'));
    format = format.replace('YYYY', year);

    return format;
  }

  const postProductUpdate = async () => {
    const activeCat = productData.categoryId;
    const validationObject = {
      name: productData.name?.length > 3,
      price: productData.price !== null,
      categoryId: productData.categoryId !== null,
      description: productData.description?.length > 0,
      images: productData.images?.length > 0,
    };
    setProdValidation(validationObject);

    const isValidForm = Object.values(validationObject).every((value) => value === true);
    if (!isValidForm) return;

    productData.providerId =  userId;
    const formattedDate = formatDate(new Date(), 'DD-MM-YYYY'); // Customize the desired format
    productData.creationDate = formattedDate;
    const numberfy = {
      ...productData,
      price: +productData.price,
      discountPrice: +productData.discountPrice,
    };
    const modifiedObject = stringifyArrays(numberfy);
    try {
      const response = await axios.post(UpdateCreateProduct(), modifiedObject);
      const { data } = response;
      if (data.result) {
        navigate(`/provider-products`, { state: { activeCategory: activeCat } });
      } else {
        alert("Error adding product");
      }
    } catch (err) {
      console.log(err);
      alert("Error adding product");
    }
  };

  useEffect(() => {
    fetchCategoriesList();
  }, []);

  useEffect(() => {
    setProductData({ ...initialProductTemplate });
  }, [location.pathname]);

  return (
    <div className={Styles.containerUser} style={{ marginRight: '70px' }}>
      <Typography variant="h4" component="h3" style={{ color: '#154360', marginTop: '5vh' }}>
        הוספת מוצר
      </Typography>

      <IconButton style={{ position: 'absolute', right: '195vh', top: '25vh' }} onClick={() => navigate(-1)}>
        <ArrowBackIosIcon />
      </IconButton>

      <div className="form-group row my-4">
        <label htmlFor="name" className="col-sm-2 col-form-label">שם מוצר</label>
        <div className="col-sm-10" style={{ width: '50%' }}>
          <input
            type="text"
            id="name"
            name="name"
            value={productData.name}
            onChange={(e) => handleInputEdit(e, productData.id, 'name', '')}
            className="form-control"
            style={{ borderColor: prodValidation.name ? '' : 'red' }}
          />
        </div>
      </div>

      <div className="form-group row my-4">
        <label htmlFor="price" className="col-sm-2 col-form-label">מחיר</label>
        <div className="col-sm-10" style={{ width: '50%' }}>
          <input
            type="number"
            id="price"
            name="price"
            value={productData.price ?? ""}
            onChange={(e) => handleInputEdit(e, productData.id, 'price', '')}
            className="form-control"
            onWheel={(e) => e.target.blur()}
            style={{ borderColor: prodValidation.price ? '' : 'red' }}
          />
        </div>
      </div>

      <div className="form-group row my-4">
        <label htmlFor="discountPrice" className="col-sm-2 col-form-label">מחיר הנחה</label>
        <div className="col-sm-10" style={{ width: '50%' }}>
          <input
            type="number"
            id="discountPrice"
            name="discountPrice"
            value={productData.discountPrice}
            onChange={(e) => handleInputEdit(e, productData.id, 'discountPrice', '')}
            className="form-control"
            onWheel={(e) => e.target.blur()}
          />
        </div>
      </div>

      <div className="form-group row my-4">
        <label htmlFor="description" className="col-sm-2 col-form-label">תיאור</label>
        <div className="col-sm-10" style={{ width: '50%' }}>
          <textarea
            id="description"
            name="description"
            value={productData.description ?? ""}
            onChange={(e) => handleInputEdit(e, productData.id, 'description', '')}
            className="form-control"
            rows="3"
            style={{ borderColor: prodValidation.description ? '' : 'red' }}
          />
        </div>
      </div>

      <div className="form-group row my-4">
  <label htmlFor="categoryId" className="col-sm-2 col-form-label">קטגוריה</label>
  <div className="col-sm-10" style={{ width: '50%' }}>
    <select
      id="categoryId"
      name="categoryId"
      value={productData.categoryId ?? ""}
      onChange={(e) => handleCategoryFilter(e, e.target.value)}
      className="form-control"
      style={{ borderColor: prodValidation.categoryId ? '' : 'red' }}
    >
      <option value="">בחר קטגוריה</option>
      {categoriesList.map((category) => (
        <option key={category.id} value={category.id}>
          {category.niceName}
        </option>
      ))}
    </select>
  </div>
</div>


  
      <div className="form-group row my-4">
        <label htmlFor="providerId" className="col-sm-2 col-form-label">תמונה</label>
        <div className="col-sm-10" style={{ width: '50%' }}>
        <div className="form-group row my-4">
          <div className="col-sm-10" style={{ width: '50%' }}>
            <Button
              variant="contained"
              component="label"
              style={{backgroundColor: '#154360'}}

              error={!prodValidation.images}
              helperText={!prodValidation.images && 'Image is required'}
            >
              {langData[langConfig.userType][langConfig.lang].editProduct.buttons.addBtn}
              <input type="file" hidden onChange={addImage} accept=".jpg, .jpeg, .png, .gif, .svg" />
            </Button>
          </div>
        </div>
        <div className="form-group row my-4">
          <div className="col-sm-10" style={{ width: '50%' }}>
            {productData.images !== "" && productData.images.split(',').map((imageUrl, index) => {
              if (imageUrl !== "[]" && index > 0) {
                return (
                  <div className={Styles2.imageWrapper} key={imageUrl}>
                    <span className={Styles2.removeWrapper}>
                      <DeleteIcon onClick={() => deleteImage(index)} />
                    </span>
                    <img className={Styles2.productImage} src={`${DOMAIN}${imageUrl}`} alt="" />
                  </div>
                );
              }
            })}
          </div>
        </div>
        </div>
      </div>



      <div className="form-group row my-4">
        <label htmlFor="discountPrice" className="col-sm-2 col-form-label">תכונות</label>
        <div className="col-sm-10" style={{ width: '50%' }}>
        <Button
                      style={{backgroundColor: '#154360'}}

         variant="contained" color="primary" onClick={() => addItem('variations')} className="mb-3">
          {langData[langConfig.userType][langConfig.lang].editProduct.buttons.addBtn}
        </Button>
        {productData.variations.map((variation) => (
          <div className="form-group row my-4" key={variation.id}>
            <div className="col-sm-2">
              <IconButton onClick={() => removeItem('variations', variation.id)}>
                <DeleteIcon />
              </IconButton>
            </div>
            <div className="col-sm-5">
              <input
                type="text"
                id={`variationName-${variation.id}`}
                name="variationName"
                value={variation.name}
                onChange={(e) => handleInputEdit(e, variation.id, 'variations', 'name')}
                className="form-control"
                placeholder={langData[langConfig.userType][langConfig.lang].editProduct.name}
              />
            </div>
            <div className="col-sm-5">
              <input
                type="text"
                id={`variationValues-${variation.id}`}
                name="variationValues"
                value={variation.values}
                onChange={(e) => handleInputEdit(e, variation.id, 'variations', 'values')}
                className="form-control"
                placeholder='| להפרדה בין הערכי'
              />
            </div>
          </div>
        ))}
        </div>
      </div>


      <div className="form-group row my-4">
        <label htmlFor="discountPrice" className="col-sm-2 col-form-label">סוג משלוח</label>
        <div className="col-sm-10" style={{ width: '50%' }}>
        <Button
                      style={{backgroundColor: '#154360'}}

         variant="contained" color="primary" onClick={() => addItem('deliveryTypes')} className="mb-3">
          {langData[langConfig.userType][langConfig.lang].editProduct.buttons.addBtn}
        </Button>
        {productData.deliveryTypes.map((deliverytype) => (
          <div className="form-group row my-4" key={deliverytype.id}>
            <div className="col-sm-2">
              <IconButton onClick={() => removeItem('deliveryTypes', deliverytype.id)}>
                <DeleteIcon />
              </IconButton>
            </div>
            <div className="col-sm-5">
              <input
                type="text"
                id={`deliveryName-${deliverytype.id}`}
                name="deliveryName"
                value={deliverytype.name}
                onChange={(e) => handleInputEdit(e, deliverytype.id, 'deliveryTypes', 'name')}
                className="form-control"
                placeholder={langData[langConfig.userType][langConfig.lang].editProduct.name}
              />
            </div>
            <div className="col-sm-5">
              <input
                type="text"
                id={`deliveryPrice-${deliverytype.id}`}
                name="deliveryPrice"
                value={deliverytype.price}
                onChange={(e) => handleInputEdit(e, deliverytype.id, 'deliveryTypes', 'price')}
                className="form-control"
                placeholder={langData[langConfig.userType][langConfig.lang].editProduct.price}
              />
            </div>
          </div>
        ))}
        </div>
      </div>





      <div className="form-group row my-4">
        <label htmlFor="discountPrice" className="col-sm-2 col-form-label">תיאור מורחב</label>
        <div className="col-sm-10" style={{ width: '50%' }}>
        <Button
                      style={{backgroundColor: '#154360'}}

         variant="contained" color="primary" onClick={() => addItem('expandedDescription')} className="mb-3">
          {langData[langConfig.userType][langConfig.lang].editProduct.buttons.addBtn}
        </Button>
        {productData.expandedDescription.map((description) => (
          <div className="form-group row my-4" key={description.id}>
            <div className="col-sm-2">
              <IconButton onClick={() => removeItem('expandedDescription', description.id)}>
                <DeleteIcon />
              </IconButton>
            </div>
            <div className="col-sm-5">
              <input
                type="text"
                id={`expandedTitle-${description.id}`}
                name="expandedTitle"
                value={description.title}
                onChange={(e) => handleInputEdit(e, description.id, 'expandedDescription', 'title')}
                className="form-control"
                placeholder={langData[langConfig.userType][langConfig.lang].editProduct.title}
              />
            </div>
            <div className="col-sm-5">
              <textarea
                id={`expandedText-${description.id}`}
                name="expandedText"
                value={description.text}
                onChange={(e) => handleInputEdit(e, description.id, 'expandedDescription', 'text')}
                className="form-control"
                rows="3"
                placeholder={langData[langConfig.userType][langConfig.lang].editProduct.text}
              />
            </div>
          </div>
        ))}
        </div>
      </div>





      <Button
        variant="contained"
        style={{backgroundColor: '#154360'}}

        className="mb-5"
        onClick={postProductUpdate}
      >
        {langData[langConfig.userType][langConfig.lang].editProduct.buttons.saveBtn}
      </Button>
    </div>
  );
}


export default AddProduct;
