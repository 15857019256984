import React, { useState } from "react";
import Styles from "./table.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import { updateUserDetails } from "../../../config/api";
import { Button } from 'react-bootstrap'


const TableUsers = ({
  data,
  tableLoading,
  heading,
  type,
  updateUser,
  fetchData,
  langConfig,
  langData,
  isAdmin
}) => {
  const navigate = useNavigate();
  const [editingElement, setEditingElement] = useState(null)
  const [editableValue, setEditableValue] = useState('')
  console.log('table_data:', data)


  const updateProviderData = async (obj, key) => {

    console.log('updateProviderData: ', obj)
    const {
      firstName,
      lastName,
      id } = obj;

    const updatedObject = {
      userId: id,
      firstName: key === 'firstName' ? editableValue : firstName,
      lastName: key === 'lastName' ? editableValue : lastName,
    }

    try {

      const response = await axios.post(updateUserDetails(), updatedObject)

      console.log('response.result', response)
      if (response.data.result) {
        setEditingElement(null)
        setEditableValue('')
        // handleGetMainCategories()
        fetchData();
      } else {
        alert('Error updating updateProviderData')
      }

    } catch (error) {
      console.log('Error updating updateProviderData:', error)
      alert('Error updating updateProviderData:', error)
    }

    // const newObj = {...obj, [key] : editableValue}
    console.log('updateProductCat: ', updatedObject)
  }

  const mapProvider = (id) => {
    console.log('check idd', id)
    navigate(`/categories/${id}`);
  };

  const editUser = (id) => {
    navigate(`/edit-user/${id}`);
  };

  const importProducts = (id) => {
    console.log('check idd', id)
    navigate(`/import-products/${id}`);
  };

  const editElmentHandler = (id) => {
    // const input = document.querySelector(`#input-${id}`)
    // input.focus()
    setEditingElement(id)
    console.log('editElmentHandler: ', id)
  }


  console.log('type___', type)

  return (
    <div className="card mt-5">
      <div className="card-header">
        <h2 className="card-title">
משתמשים        </h2>
      </div>
      <div className="card-body">
        <div className={Styles.tableResponsive}>
          <table className={`${Styles.table} ${Styles.tableStriped} ${Styles.usersTables}`}>
            <thead>
              <tr>
                {/* <th className={Styles.horizantal}>
                  {langData[langConfig.userType][langConfig.lang].users.table.action}
                </th> */}
                <th>
                  {langData[langConfig.userType][langConfig.lang].users.table.fname}
                </th>
                <th>
                  {langData[langConfig.userType][langConfig.lang].users.table.lname}
                </th>
                <th>
                  {langData[langConfig.userType][langConfig.lang].users.table.email}
                </th>
                <th>
                  {langData[langConfig.userType][langConfig.lang].users.table.phone}
                </th>
                
             {/*   <th>
                  {langData[langConfig.userType][langConfig.lang].users.table.birthday}
              </th>*/}
              <th>כסף בקופה</th>
                {!isAdmin && (
                  <>
                    <th>
                      {langData[langConfig.userType][langConfig.lang].users.table.role}
                    </th>

                    <th>
                      {langData[langConfig.userType][langConfig.lang].users.table.companyName}
                    </th>
                    <th>
                      {langData[langConfig.userType][langConfig.lang].users.table.bankAccId}
                    </th>
                  </>
                )}
                <th>
                  {langData[langConfig.userType][langConfig.lang].users.table.creationDate}
                </th>
                {type === "User" ? (
                  <>
                    <th>
                      {langData[langConfig.userType][langConfig.lang].users.table.numOfOrders}
                    </th>
                   {/* <th>
                      {langData[langConfig.userType][langConfig.lang].users.table.totalOrders}

                </th>*/}
                    <th></th>
                  </>
                ) : (
                  <>
                    <th>Number of Products</th>
                    <th>Number of Orders</th>
                   <th>Total Orders</th>
                    <th></th>

                  </>
                )}


              </tr>
            </thead>
            {!tableLoading ? (
              <tbody>
                {data?.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      {/* <td className="d-flex justify-content-between align-items-center">
                        <label className={Styles.switch}>
                          <input
                            type="checkbox"
                            name="switch"
                            // value={item.isEnable}
                            checked={item.isEnable}
                            onChange={() => {
                              updateUser(item.id, !item.isEnable);
                            }}
                          />
                          <span
                            className={`${Styles.slider} ${Styles.round}`}
                          ></span>
                        </label>
                        {type !== "User" && (
                          <button style={{
                            margin: '10px',
                            width: '85px',
                          }}
                            className="btn btn-primary ml-1 actionbutton"
                            onClick={() => mapProvider(item.id)}
                          >
                            Map Provider
                          </button>
                        )}
                        {type !== "User" && (
                          <button style={{
                            margin: '10px',
                            width: '85px',
                          }}
                            className="btn btn-primary ml-1 actionbutton"
                            onClick={() => importProducts(item.id)}
                          >
                            Import Prods
                          </button>
                        )}
                      </td> */}

                      <td>
                        {editingElement == `${index}firstName` ? (
                          <>
                            <input
                              onChange={(e) => setEditableValue(e.target.value)}
                              id={`input-${index}firstName`} type="text" defaultValue={item.firstName} />
                            <button onClick={() => updateProviderData(item, 'firstName')}>Save</button>
                          </>
                        ) : (
                          <>
                            <span>
                              {item.firstName}
                            </span>
                            <EditIcon onClick={() => editElmentHandler(`${index}firstName`)} />
                          </>
                        )}
                      </td>
                      <td>
                        {editingElement == `${index}lastName` ? (
                          <>
                            <input
                              onChange={(e) => setEditableValue(e.target.value)}
                              id={`input-${index}lastName`} type="text" defaultValue={item.lastName} />
                            <button onClick={() => updateProviderData(item, 'lastName')}>Save</button>
                          </>
                        ) : (
                          <>
                            <span>
                              {item.lastName}
                            </span>
                            <EditIcon onClick={() => editElmentHandler(`${index}lastName`)} />
                          </>
                        )}
                        { }
                      </td>
                      <td>{item.email}</td>
                      <td>{item.phoneNumber}</td>
                    {/*  <td>{item.dob ? item.dob.split('T')[0].split('-').reverse().join('/') : ''}</td>*/}
                    <td>{item.myBank}</td>
                      <td>{item.сreationDate ? item.сreationDate.split('T')[0].split('-').reverse().join('/') : ''}</td>

                      {!isAdmin && (
                        <>
                          <td>{item.role}</td>
                          <td>{item.companyName}</td>
                          <td>{item.bankAccountId}</td>

                        </>
                      )}
                      {type === "User" ? (
                        <>
                          {console.log('numbersOfOrdersOfBuyer', item.numbersOfOrdersOfBuyer)}
                          {console.log('user_item', item.sumOfPaidOrders)}

                          <td>{item.numbersOfOrdersOfBuyer}</td>
                          {/*<td>{(item.numbersOfOrdersOfProvider ?? 0) + (item.numbersOfOrdersOfBuyer)}</td>*/}



                          <td>
                            <Button
                              onClick={() => editUser(item.id)}
                            >
                              עריכה
                            </Button>
                          </td>



                        </>
                      ) : (
                        <>
                          {console.log('debugNumberOfProducts user_provider', item)}
                          {console.log('user_provider', item.numbersOfOrders)}
                          {console.log('user_provider', item.amount)}

                          <td>{item.numbersOfProducts}</td>
                          <td>{item.numbersOfOrders}</td>
                          <td>{item.amount}</td>



                        </>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={type === "User" ? "12" : "13"}
                      className="text-center py-5"
                    >
                      No data
                    </td>
                  </tr>
                )}


              </tbody>
            ) : (
              <tbody>
                <>
                  <tr>
                    <td
                      colSpan={type === "User" ? "12" : "13"}
                      className="text-center"
                      style={{
                        height: "200px",
                      }}
                    >

                      <div
                        className="spinner-border text-primary"
                        role="status"
                      ></div>
                    </td>

                  </tr>


                </>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>

  );
};

export default TableUsers;
