import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import "./assets/css/App.css";
import User from "./pages/User-Data/User";
import Product from "./pages/Product-Data/Product";
import Header from "./components/Header/Header";
import Categories from "./pages/Categories/Categories";
import Reports from "./pages/reports/Reports";
import AbandonedCart from "./pages/abandoned-cart/AbandonedCart";

import AuthPopup from "./components/Auth/AuthPopup";
import { getProviderOrders, getProviderOrdersById } from "../config/api";
import axios from "axios";
import Orders from "./pages/Orders/Orders";
import ReportsManagement from "./pages/Orders/ReportsManagement";
import ProductList from "./pages/Product-list/ProductList";
import ProductCatManagement from "./pages/Product-cat-mangement/ProductCatManagement";
import SelectedCategoryProducts from "./pages/SelectedCategoryProducts";
import Profiles from "./pages/Providers/Profiles";
import ProductsProvider from "./pages/products-provider/ProductsProvider";
import EditProduct from "./pages/edit-product-provider/EditProduct";
import EditProductAdmin from "./pages/edit-product-provider/EditProductAdmin";

import jsonTranslate from '../jsonTranslate_29-09-23.json'
import AddProduct from "./pages/add-product-provider/AddProduct";
import AddProductAdmin from "./pages/add-product-admin/AddproductAdmin";

import EditUser from "./pages/edit-user/EditUser"
import EditProvider from "./pages/edit-provider/EditProvider";
import EditCategoryProduct from "./pages/edit-category-product/EditCategoryProduct";
import AddCategoryProduct from "./pages/edit-category-product/AddCategoryProduct";
import EditOrder from "./pages/edit-order/EditOrder";
import EditWishlist from "./pages/edit-wishlist/EditWishlist";
import EditMainCategory from "./pages/edit-main-category/EditMainCategory";
import AddMainCategory from "./pages/edit-main-category/AddMainCategory";
import AddProvider from "./pages/add-provider/AddProvider"
import AllProducts from "./pages/all-products/AllProducts";
import AllProductsManagement from "./pages/all-products/all-products-management";
import AddWishlist from "./pages/edit-wishlist/AddWishlist";
const { toggleLang: langData } = jsonTranslate;
console.log(langData, 'langData')
function App() {

  console.log('jsonTranslate', jsonTranslate.admin)
  const navigate = useNavigate()
  const location = useLocation();
  let isLoggedIn = localStorage.getItem('UserId') ? false : true;
  const [isAuthPopup, setIsAuthPopup] = useState(isLoggedIn)
  const [userId, setUserId] = useState(localStorage.getItem('UserId'))
  const phoneFromLocal = localStorage.getItem('phoneNumber') ? localStorage.getItem('phoneNumber').trim() : "";
  const [phoneNumber, setPhoneNumber] = useState(phoneFromLocal);
  const ADMIN_PHONES = ['0585444533', '0542462800']
  const [isAdmin, setIsAdmin] = useState(false)
  const savedLang = localStorage.getItem('lang') ?? "hebrew";
  const [langConfig, setLangConfig] = useState({
    lang: "hebrew",
    userType: "provider",
  })

  const handleLangChange = (lang) => {
   /* setLangConfig(prevConf => ({ ...prevConf, lang }))
    localStorage.setItem('lang', lang)*/
  }

  const phoneNumberHandler = (value) => {
    setPhoneNumber(value)
  }
  console.log(isAdmin, 'isAdmin')

  const authPopupToggle = () => {
    setIsAuthPopup(!isAuthPopup)
  }

  useEffect(() => {
    if (!isAdmin) {
      navigate('/orders')
    } else {
      navigate('/')
    }
  }, [isAdmin])


  useEffect(() => {
    setIsAdmin(ADMIN_PHONES.includes(phoneNumber))
    setLangConfig(prevConf => ({
      ...prevConf,
      userType: ADMIN_PHONES.includes(phoneNumber) ? 'admin' : 'provider'
    }));
  }, [phoneNumber])

  useEffect(() => {
    const fetchProvidersOrder = async (userId) => {
      // e.preventDefault()
      console.log('fetchOTP')
      try {
        const response = await axios.get(getProviderOrders(userId));
        const data = response.data;
        console.log(data)
        if (data.result) {
          // setFormStep(fs => fs + 1)
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (!isAdmin) return;
    fetchProvidersOrder(userId)


    return () => {

    }
  }, [userId, isAdmin])
  console.log(langConfig, 'langConfig')
  return (
    <div className="App">
      {isAuthPopup && (
        <AuthPopup
          onClose={authPopupToggle}
          phoneNumberHandler={phoneNumberHandler}
          phoneNumber={phoneNumber}
          langData={langData}
          langConfig={langConfig}
        />
      )}
      <Header
        langData={langData}
        langConfig={langConfig}
        handleLangChange={handleLangChange}
        isAdmin={isAdmin}
        userId={userId}

      />
      <Routes>
        {isAdmin && (
          <>
            <Route path="/" element={
              <User
                isAdmin={isAdmin}
                userId={userId}
                langData={langData}
                langConfig={langConfig}
              />
            } />
            {/* <Route path="/providers" element={<Profiles isAdmin={isAdmin} userId={userId} />} /> */}
          </>
        )}
        <Route path="/provider" element={
          <Profiles
            isAdmin={isAdmin}
            userId={userId}
            langData={langData}
            langConfig={langConfig}
          />
        } />
        <Route path="/import-products/:companyId" element={
          <Product
            langData={langData}
            langConfig={langConfig} />
        } />

     <Route path="/reports" element={
          <ReportsManagement
            langData={langData}
            langConfig={langConfig}
            isAdmin={isAdmin}
            userId={userId} />
            

        } />
        {/* <Route path="/categories-management" element={<ProductList />} /> */}
        <Route path="/categories/:id" element={
          <Categories
            langData={langData}
            langConfig={langConfig}
            isAdmin={isAdmin}
          />
        } />
        <Route path="/selected-category-product/:id" element={
          <SelectedCategoryProducts
            userId={userId}
            isAdmin={isAdmin}
            langData={langData}
            langConfig={langConfig}
          />
        } />
        <Route path="/orders" element={
          <Orders
            userId={userId}
            isAdmin={isAdmin}
            langData={langData}
            langConfig={langConfig}
          />}
        />
        <Route path="/provider-products" element={
          <ProductsProvider
            userId={userId}
            langData={langData}
            langConfig={langConfig}
            isAdmin={isAdmin}
          />} />
        <Route path={`/provider-products/:productId`} element={
          <EditProduct
            userId={userId}
            langData={langData}
            langConfig={langConfig}
          />
          
        } />
 


                <Route path={`/all-products-management/:productId`} element={
                  
          <EditProductAdmin
            userId={userId}
            langData={langData}
            langConfig={langConfig}
          />
        } />
        <Route path={`/provider-products/add-product`} element={
          <AddProduct
            userId={userId}
            langData={langData}
            langConfig={langConfig}
          />
        } />
        <Route path="/edit-user/:id" element={
          <EditUser />} />

         <Route path="/edit-product-category/:id" element={
          <EditCategoryProduct />} />

  <Route path="/add-product-category" element={
          <AddCategoryProduct />} />


        <Route path="/edit-order/:id" element={
          <EditOrder />} />


          <Route path="/edit-wishlist/:id" element={
          <EditWishlist />} />

          <Route path="/add-wishlist" element={
          <AddWishlist />} />  

          <Route path="/edit-main-category/:id" element={
          <EditMainCategory />} />

          <Route path="/add-main-category" element={
          <AddMainCategory />} />


                <Route path="/edit-wishlist/:id" element={
          <EditWishlist />} />
          
          <Route path="/add-provider" element={
          <AddProvider 
          isAdmin={isAdmin}
         />} />

        <Route path="/edit-provider/:id" element={
          <EditProvider 
          langConfig={langConfig}
          langData={langData}
          isAdmin={isAdmin}
          />} />
                 <Route path="/add-product-admin" element={
          <AddProductAdmin 
          langConfig={langConfig}
          langData={langData}
          isAdmin={isAdmin}
          />} />
       <Route path="/all-products-management" element={
          <AllProductsManagement
          langConfig={langConfig}
          langData={langData}
          isAdmin={isAdmin}
          />} />
        {isAdmin && <Route path="/product-categories-management" element={
          <ProductCatManagement
            isAdmin={isAdmin}
            langData={langData}
            langConfig={langConfig}
          />
        } />}
      {isAdmin && <Route path="/abandoned-cart" element={
          <AbandonedCart
            isAdmin={isAdmin}
            langData={langData}
            langConfig={langConfig}
            
          />
        } />}
      </Routes>
    </div>
  );
}

export default App;
