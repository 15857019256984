import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import TableProducts from '../../components/Tables/TableProducts';
import Styles from '../Orders/Orders.module.css';
import { getProductsByFilters, getProductsNames, getProvidersNames, getProviderCategoriesListOptions, removeProductById } from '../../../config/api'; // Ensure correct import

export default function ProductsProvider({
  userId,
  langData,
  langConfig,
  isAdmin
}) {
  const location = useLocation();
  const [heading, setHeading] = useState('Products');
  const [dataLoading, setDataLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(30); // Page size
  const [totalItems, setTotalItems] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [selectedCode, setSelectedCode] = useState('');
  const [productName, setProductName] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [categoryId, setCategoryId] = useState(null); // Ensure categoryId is an object
  const [productNameOptions, setProductNameOptions] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);

  // Fetch products with applied filters
  const fetchProducts = async (page = 1, reset = false) => {
    setDataLoading(true);
    console.log('Fetching products with filters:', {
      pageNumber: page,
      pageSize: itemsPerPage,
      code: selectedCode,
      productName: productName?.value || '',
      providerId: selectedProvider?.value || '',
      categoryId: categoryId?.value || '' // Use categoryId.value if categoryId is an object
    });
    try {
      const response = await axios.post(getProductsByFilters(), {
        pageNumber: page,
        pageSize: itemsPerPage,
        code: selectedCode,
        productName: productName?.value || '',
        providerId: userId,
        categoryId: categoryId?.value.toString() || '' // Use categoryId.value if categoryId is an object
      });
      const { products, totalItems } = response.data;
      if (reset) {
        setProducts(products);
      } else {
        setProducts(prevProducts => [...prevProducts, ...products]);
      }
      setTotalItems(totalItems);
      setHasMore(page * itemsPerPage < totalItems);
      console.log('Products fetched:', products);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
    setDataLoading(false);
  };

  const fetchProviderOptions = async () => {
    try {
      const response = await axios.post(getProvidersNames(), {});
      console.log("Fetched Providers:", response.data);
      const options = response.data.providersNames.map(provider => ({
        value: provider.providerId,
        label: provider.providerName,
      }));
      console.log('Fetched Provider Options:', options);
      setProviderOptions(options);
    } catch (error) {
      console.error('Error fetching provider options:', error);
    }
  };

  const fetchProductNameOptions = async (inputValue) => {
    if (inputValue.length < 2) return [];
    try {
      const response = await axios.post(getProductsNames(), { word: inputValue, limit: 10 });
      const options = response.data.productNames.map(name => ({
        value: name,
        label: name,
      }));
      console.log('Fetched Product Name Options:', options);
      return options;
    } catch (error) {
      console.error('Error fetching product name options:', error);
      return [];
    }
  };

  const fetchCategoriesOptions = async () => {
    try {
      const response = await axios.get(getProviderCategoriesListOptions());
      console.log("Fetched categories data:", response.data.data);
      const options = response.data.data.map(item => ({
        value: item.id,
        label: item.niceName,
      }));
      console.log('Fetched Category Options:', options);
      setCategoriesList(options);
    } catch (error) {
      console.error('Error fetching category options:', error);
    }
  };

  useEffect(() => {
    fetchCategoriesOptions();
    fetchProviderOptions();
  }, []);

  // Fetch products whenever any filter changes
  useEffect(() => {
    fetchProducts(1, true);
  }, [selectedCode, productName, selectedProvider, categoryId]);

  const handleCodeFilter = (event) => {
    setSelectedCode(event.target.value);
    setCurrentPage(1);
  };

  const handleProductNameSelectChange = (selectedOption) => {
    setProductName(selectedOption);
    setCurrentPage(1);
  };

  const handleProviderSelectChange = (selectedOption) => {
    setSelectedProvider(selectedOption);
    setCurrentPage(1);
  };

  const handleCategoryIdFilter = (selectedOption) => {
    setCategoryId(selectedOption);
    setCurrentPage(1);
  };

  const fetchMoreData = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    fetchProducts(nextPage);
  };



  const removeProduct = async (id) => {
    debugger;
    if (!id) return;
    const confirmRemoval = window.confirm("האם למחוק מוצר זה?");
    if (confirmRemoval) {
      try {
        const response = await axios.post(removeProductById(), { id }); // Ensure id is sent as an object
        if (response.data.result) {
          const updatedProducts = products.filter(product => product.id !== id);
          setProducts(updatedProducts);
        }
        alert('Something went wrong removing the product');
      } catch (err) {
        console.log(err);
        alert('Something went wrong removing the product');
      }
    }
  };
  return (
    <div className={Styles.providerProducts}>
      <TableProducts
        data={products}
        tableLoading={dataLoading}
        heading={heading}
        langData={langData}
        langConfig={langConfig}
        isAdmin={isAdmin}
        hasMore={hasMore}
        fetchMoreData={fetchMoreData}
        onCodeFilterChange={handleCodeFilter}
        onProductNameSelectChange={handleProductNameSelectChange}
        onProviderSelectChange={handleProviderSelectChange}
        onCategoryIdFilterChange={handleCategoryIdFilter}
        productNameOptions={productNameOptions}
        productName={productName}
        providerOptions={providerOptions}
        selectedProvider={selectedProvider}
        categoriesList={categoriesList}
        selectedCategory={categoryId}
        mainCategories={categoriesList}
        userId={userId}
        removeProduct={removeProduct}
      />
    </div>
  );
}
