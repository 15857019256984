import React, { useCallback, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import Styles from '../../components/Tables/table.module.css';
import Styles2 from '../../components/Tables/table-products.module.css';
import axios from 'axios';
import { getProductsNames } from '../../../config/api'; // Ensure correct import

const TableProducts = ({
  userId,
  data,
  tableLoading,
  heading,
  langData,
  langConfig,
  isAdmin,
  hasMore,
  fetchMoreData,
  onCodeFilterChange,
  onProviderSelectChange,
  onCategoryIdFilterChange,
  productNameOptions,
  productName,
  onProductNameSelectChange,
  providerOptions,
  selectedProvider,
  categoriesList,
  selectedCategory,
  mainCategories,
  removeProduct
}) => {
  const navigate = useNavigate();

  const editProduct = (product) => {
    navigate(`${product.id}`, { state: { product, mainCategories } });
  };

  useEffect(() => {
    console.log("XXXXXXXXXXXXX", mainCategories);
  }, [categoriesList]);

  const loadProductOptions = async (inputValue, callback) => {
    console.log(`Input value: ${inputValue}`);

    if (inputValue.length < 2) {
      console.log("Input length is less than 2, returning empty array");
      callback([]);
      return;
    }

    try {
      console.log(`Fetching product names for input: ${inputValue}`);
      const response = await axios.post(getProductsNames(), { word: inputValue, pageSize: 10, pageNumber: 1, providerId: userId });
      console.log("Response data:", response.data);

      if (response.data.productNames) {
        const options = response.data.productNames.map(name => ({
          value: name,
          label: name,
        }));
        console.log("Mapped options:", options);
        callback(options);
      } else {
        console.warn("No product names found in the response.");
        callback([]);
      }
    } catch (error) {
      console.error('Error fetching product name options:', error);
      callback([]);
    }
  };

  const loadProviderOptions = useCallback(
    debounce((inputValue, callback) => {
      const filteredOptions = providerOptions.filter(option =>
        option.label && option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(filteredOptions);
    }, 300),
    [providerOptions]
  );

  const loadCategoryOptions = useCallback(
    debounce((inputValue, callback) => {
      const filteredOptions = categoriesList.filter(option =>
        option.label && option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(filteredOptions);
    }, 300),
    [categoriesList]
  );

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <Button onClick={() => navigate('add-product')}>הוסף מוצר</Button>
      </div>
      <div className="card mt-5">
        <div className="card-body">
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Group className="mb-3" controlId="formProductCode" style={{ flex: '0 0 23%', marginLeft: '15px' }}>
              <Form.Label>קוד מוצר</Form.Label>
              <Form.Control type="text" placeholder="הכנס קוד מוצר" onChange={onCodeFilterChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formProductName" style={{ flex: '0 0 23%' }}>
              <Form.Label>שם מוצר</Form.Label>
              <AsyncSelect
                loadOptions={loadProductOptions}
                onChange={onProductNameSelectChange}
                value={productName}
                placeholder="הכנס שם מוצר"
                isClearable
                isSearchable
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formCategoryId" style={{ flex: '0 0 23%', marginRight: '13px' }}>
              <Form.Label>קטגוריה</Form.Label>
              <AsyncSelect
                cacheOptions
                loadOptions={loadCategoryOptions}
                defaultOptions={categoriesList}
                onChange={onCategoryIdFilterChange}
                value={selectedCategory}
                placeholder="הכנס קטגוריה"
                isClearable
                isSearchable
              />
            </Form.Group>
          </div>
          <div className="card-header">
            <h2 className="card-title">מוצרים</h2>
          </div>
          <div className={Styles.tableResponsive}>
            <InfiniteScroll
              dataLength={data.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<h4>טוען...</h4>}
              endMessage={<p style={{ textAlign: 'center' }}>כל המוצרים הוצגו</p>}
            >
              <table className={`${Styles.table} ${Styles.tableStriped}`}>
                <thead>
                  <tr>
                    <th>קוד</th>
                    <th>{langData[langConfig.userType][langConfig.lang].tab5.table.prodName}</th>
                    <th>תמונה</th>
                    <th>{langData[langConfig.userType][langConfig.lang].tab5.table.price}</th>
                    <th>{langData[langConfig.userType][langConfig.lang].tab5.table.discountPrice}</th>
                    <th>{langData[langConfig.userType][langConfig.lang].tab5.table.description}</th>
                    <th>תאריך הכנסה</th>
                    {isAdmin && <th>{langData[langConfig.userType][langConfig.lang].tab5.table.providerId}</th>}
                    <th>קטגוריה</th>
                    <th></th>
                  </tr>
                </thead>
                {!tableLoading ? (
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item, index) => (
                        <tr key={index} style={{ verticalAlign: 'top' }}>
                          <td>{item.productCode}</td>
                          <td>{item.name}</td>
                          <td className={Styles2.imagesContainer}>
                            <div className={Styles2.imagesHolder}>
                              <div className={Styles2.imageWrapper}>
                              <img
  className={Styles2.productImage}
  src={
    !item.manuallyAdd
      ? item.images
      : `https://server.yyyshopping.com${item.images.replace(",", "")}`
  }
  alt=""
/>



                              </div>
                            </div>
                          </td>
                          <td>
                            {item.price?
                            <> <span>₪</span>{item.price}</>
                            :""
                            }
                            </td>
                            <td>
                            {item.discountPrice?
                            <> <span>₪</span>{item.discountPrice}</>
                            :""
                            }
                            </td>
                           
                          <td dangerouslySetInnerHTML={{ __html: item.description }}></td>
                          <td>
                            {item?.creationDate && (() => {
                              const date = new Date(item.creationDate);
                              const day = ("0" + date.getDate()).slice(-2);
                              const month = ("0" + (date.getMonth() + 1)).slice(-2);
                              const year = date.getFullYear();
                              return `${day}/${month}/${year}`;
                            })()}
                          </td>
                          {isAdmin && <td>{item.providerId}</td>}
                          <td>{mainCategories.find(cat => cat.id === item.categoryId)?.niceName}</td>
                          <td className={Styles2.controlBtns}>
                            <Button onClick={() => editProduct(item)}>
                              {langData[langConfig.userType][langConfig.lang].tab5.table.editBtn}
                            </Button>
                            <Button onClick={() => removeProduct(item.id)}>
                              {langData[langConfig.userType][langConfig.lang].tab5.table.removeBtn}
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={isAdmin ? 10 : 9} className="text-center py-5">No data</td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={isAdmin ? 10 : 9} className="text-center" style={{ height: "200px" }}>
                        <div className="spinner-border text-primary" role="status"></div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableProducts;
