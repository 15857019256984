import React, { useState } from 'react'
import Header from './Header'
import CategoriesProduct from './ProductCategories/Categories'
import CategoriesMain from './MainCategories/Categories'
import CategoriesWishlist from './WishlistCategories/Categories'
function ProductCatManagement({ isAdmin, langConfig, langData }) {
  const [activeTab, setActiveTab] = useState(2)

  const activeTabHandle = (id) => {
    setActiveTab(id)
  }
  return (

    <>
      <Header
        isAdmin={isAdmin}
        activeTabHandle={activeTabHandle}
        activeTab={activeTab}
        langData={langData}
        langConfig={langConfig}
      />

      {activeTab === 0 && <CategoriesProduct
        langData={langData}
        langConfig={langConfig}
        setActiveTab = {setActiveTab}
      />}
      {activeTab === 1 && <CategoriesMain
        langData={langData}
        langConfig={langConfig}
        setActiveTab={setActiveTab}

      />}
      {activeTab === 2 &&
       <CategoriesWishlist
        langData={langData}
        langConfig={langConfig}
        setActiveTab = {setActiveTab}

      />}

    </>

  )
}

export default ProductCatManagement