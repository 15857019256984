import React from 'react'
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import { Button, Modal, Form } from 'react-bootstrap';
import Styles from './AuthPopup.module.css'
import { getOTP } from '../../../config/api';
import axios from 'axios';
import { useState } from 'react';
import { verifyOTPEndpoint, phoneNumberHandler, getProviderOrders } from '../../../config/api';

function AuthPopup({
  onClose,
  phoneNumberHandler,
  phoneNumber,
  langData,
  langConfig
}) {
  const [formStep, setFormStep] = useState(0)
  const [otpInput, setOtpInput] = useState(null)
  const [loginError, setLoginError] = useState(null)
  const [loading, setLoading] = useState(false)

  const formHandler = (e) => {
    switch (formStep) {
      case 0:
        fetchOTP(e)
        break;
      case 1:
        verifyOTP(e)
        break;
      default:
        break;
    }
  }

  const fetchOTP = async (e) => {
    e.preventDefault()
    if (loading) return
    setLoading(true)
    console.log('fetchOTP')
    localStorage.setItem('phoneNumber', phoneNumber)
    try {
      const response = await axios.get(getOTP(phoneNumber));
      const data = response.data;
      console.log(data)
      console.log('data?.message:', data?.message)
      if (data.result) {
        setFormStep(fs => fs + 1)
        setLoginError('')
        setLoading(false)
      } else {
        setLoginError(data?.message)
        setLoading(true)
      }
    } catch (error) {
      setLoading(true)
      console.error(error);
    }
  };

  const resendOTP = async (e) => {
    e.preventDefault()
    if (loading) return
    setLoading(true)
    console.log('fetchOTP')
    localStorage.setItem('phoneNumber', phoneNumber)
    try {
      const response = await axios.get(getOTP(phoneNumber));
      const data = response.data;
      console.log(data)
      if (data.result) {
        setLoading(false)
      } else {
        setLoading(true)
      }
    } catch (error) {
      setLoading(true)
      console.error(error);
    }
  }

  const verifyOTP = async (e) => {
    e.preventDefault()
    if (loading) return
    setLoading(true)
    const payload = { phoneNumber, otpCode: otpInput }
    try {
      const response = await axios.post(verifyOTPEndpoint(), payload);
      const data = response.data;

      console.log('verifyOTP', data)
      if (data.result) {
        const { user } = data;

        console.log('verifyOTP', user, user.isEnable)
        if (user.isEnable) {
          setFormStep(fs => fs++)
          localStorage.setItem('UserId', data.user.id)
          setLoading(false)
          window.location.reload();
        } else {
          setLoading(false)
          setLoginError('Account is disabled')
        }
      } else {
        setLoginError(data?.message)
        setLoading(true)
      }
    } catch (error) {
      setLoading(true)
      console.error(error);
    }
  }

  return (
    <div
      className={`modal show ${Styles.modalBackgroundShadow}`}
    // style={{ display: 'block', position: "initial" }}
    >
      <Modal.Dialog style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', zIndex: "1", width: '50vw' }}>
        <Modal.Header  >
          <Modal.Title>
            {langData.login[langConfig.lang].title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={formHandler}>
            {formStep == 0 && (
              <>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    {langData.login[langConfig.lang].label}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    className={Styles.customInputNumber}
                    placeholder="הכנס את מספר הפלאפון שלך"
                    value={phoneNumber}
                    onChange={(e) => {
                      phoneNumberHandler(e.target.value)
                      setLoading(false)
                      setLoginError(false)
                    }}
                  />
                </Form.Group>
                <p className={`${Styles.formControlOtpError}`} >{loginError}</p>
                <Button variant="primary"
                  style={{ pointerEvent: loading ? 'none' : 'auto' }}
                  type="submit" v
                // onClick={}
                >
                  {langData.login[langConfig.lang].title}
                </Button>
              </>
            )}

            {
              formStep == 1 && (
                <>
                  <Form.Group className={`mb-3 ${Styles.formConfirmOtp}`} controlId="exampleForm.ControlInput2">
                    <Form.Label>
                      {langData.login[langConfig.lang].label2}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      className={Styles.customInputNumber}
                      placeholder={langData.login[langConfig.lang].placeholder2}

                      value={otpInput}
                      onChange={(e) => {
                        setOtpInput(e.target.value)
                        setLoading(false)
                        setLoginError(false)
                      }}
                    />
                  </Form.Group>
                  <p className={`${Styles.formControlOtpError}`} >{loginError}</p>
                  <Button variant="primary"
                    type="submit"
                    style={{ pointerEvent: loading ? 'none' : 'auto' }}
                  // onClick={}
                  >{langData.login[langConfig.lang].btn2}
                  </Button>

                  <a href=""
                    className={`${Styles.resendOTPBtn}`}
                    onClick={resendOTP}
                  >{langData.login[langConfig.lang].btn3}
                  </a>
                </>
              )
            }

          </Form>
        </Modal.Body>

        {/* <Modal.Footer>

        </Modal.Footer> */}
      </Modal.Dialog>
    </div>
  )
}

export default AuthPopup