import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Styles from "../Categories/Categories.module.css";
import { DOMAIN } from '../../../constants/envUrl';
import EditIcon from '@mui/icons-material/Edit';

import {
  GetAllCategoriesByProductCatId,
  updateProductCategory,
  removeProductCategory,
  getMainCategories,
  uploadFile,
  addMainCategoriesToProductCategory, createProductCategory
} from '../../../config/api';

import {
  TextField,
  Button,
  Typography,
  Box,
  OutlinedInput,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
} from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddCategoryProduct = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [categoryDetails, setCategoryDetails] = useState({
    id: '',
    name: '',
    description: '',
    icon: '',
    image: '',
    mainCategory: []
  });

  const [mainCategories, setMainCategories] = useState([]);
  const [isEditing, setIsEditing] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [icon, setIcon] = useState('');
  const [image, setImage] = useState('');




  useEffect(() => {

    const fetchUserData = async () => {
      try {
        const response = await axios.get(GetAllCategoriesByProductCatId(id));
        const result = response.data;

        setCategoryDetails({
          id: id,
          name: result?.productCategory.niceName || ' ',
          description: result?.productCategory.description || ' ',
          icon: result?.productCategory.categoryIcon || ' ',
          image: result?.productCategory.categoryImage || ' ',
          mainCategory: (result?.mainCategoryList || []).map(item => ({
            id: item.category?.id,
            checked: item?.checked,
            name: item?.category?.categoryName
          })),
        });
        debugger

        setIcon(result?.productCategory.categoryIcon || '');
        setImage(result?.productCategory.categoryImage || '');

        if (result?.mainCategoryList) {
          const selectedCats = result.mainCategoryList
            .filter(item => item.checked)
            .map(item => (          
              item.category?.categoryName
            ));
            console.log(selectedCats)
          setSelectedCategories(selectedCats || []);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategoryDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const mapSelectedCategoriesToIds = (mainCategories, selectedCategories) => {
    const nameToIdMap = new Map(mainCategories.map(item => [item.name, item.id]));
  
    const resultArray = selectedCategories.map(name => nameToIdMap.get(name));
  
    return resultArray.filter(id => id !== undefined);
  };

  const handleSave = async () => {
    const resultArray = mapSelectedCategoriesToIds(mainCategories, selectedCategories);
    console.log(resultArray)

 
  
      
      const response = await axios.post(createProductCategory(), {
        name: categoryDetails.name,
        description: categoryDetails.description,
        iconLink: icon,
        imageLink: image,
      });
        debugger;
      const params = {
        categoryProductId: response.data.productCategory.id,
        mainCategoriesList: [...resultArray]
      };
   
       const response2 = await axios.post(addMainCategoriesToProductCategory(), params, {
         headers: {
            'Content-Type': 'application/json'
           }
        });
 
  
      if (response.data.result && response2.data.result) {
        navigate('/product-categories-management');
      } else {
        alert('Error updating wishlist category');
      }
   
  };
  
  const handleDelete = async () => {
    try {
      const response = await axios.post(removeProductCategory(id));

      if (response.data.result) {
        alert('נמחק');
        navigate('/');
      } else {
        alert('Error removing user');
      }
    } catch (error) {
      console.error('Error removing user:', error);
      alert(`Error removing user: ${error.message}`);
    }
  };

  const handleGetMainCategories = async () => {
    try {
      const response = await axios.get(getMainCategories());
      const { data } = response;
      let mainCatNames = null;
      if (data.result) {
        mainCatNames = data.mainCategoriesList.map((category) => {
          return { id: category.id, name: category.categoryName, checked: false };
        });
        setMainCategories(mainCatNames);
      }
      return { mainCatNames };
    } catch (error) {
      console.log(error);
      return { mainCatNames: null };
    }
  };

  useEffect(() => {
    handleGetMainCategories();
  }, []);

  const handleCheckOption = async (event) => {
    const selectedValues = event.target.value;
    
    setSelectedCategories(selectedValues);
  };

  function returnActiveCat() {
    return Array.isArray(selectedCategories) ? selectedCategories.join(', ') : "";
  }

  const uploadImage = async (event, type) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append('image', file);

        const response = await axios.post(uploadFile(), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const data = response.data;
        if (response.status !== 200) return;
        if (type === 'icon')
          setIcon(`/images/${data}`);
        if (type === 'image')
          setImage(`/images/${data}`);
      } catch (error) {
        console.error('Error uploading image:', error);
        throw error;
      }
    } else {
      console.error('No file selected');
    }
  };

  return (
    <Box
    style={{margin: '60px'}}
 
  >
    <Typography
      variant="h5"
     sx={{ color: '#154360', marginBottom: '15px', fontWeight: '700', marginBottom: '50px' }}
    >
      עריכת קטגוריה משנית
    </Typography>
    <form style={{width: '90%'}}>




    <div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">שם קטגוריה</label>
        <div className="col-sm-10"style={{width: '50%'}}>
            <input
                type="text"
                name="englishName"
                name="name"
                value={categoryDetails.name}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="form-control"
            />
        </div>
    </div>

    <div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">תיאור קטגוריה</label>
        <div className="col-sm-10"style={{width: '50%'}}>
            <input
                type="text"
                name="englishName"
                name="description"
                value={categoryDetails.description}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="form-control"
            />
        </div>
    </div>


    <div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">קטגוריות</label>
        <div className="col-sm-10"style={{width: '50%'}}>
        <FormControl sx={{ width: 500, marginTop: 1 }}>
        <InputLabel id={`demo-multiple-checkbox-label`}>קטגוריות</InputLabel>
        <Select
          labelId={`demo-multiple-checkbox-label`}
          id={`demo-multiple-checkbox`}
          multiple
          value={selectedCategories}
          onChange={(event) => handleCheckOption(event)}
          input={<OutlinedInput label="Categories" />}
          renderValue={() => returnActiveCat()}
          MenuProps={MenuProps}
        >
          {mainCategories.map((option, indexInner) => {
            const checked =
              Array.isArray(selectedCategories) ?
                selectedCategories?.findIndex((x) => x === option.name) !== -1 :
                false;
  
            return (
              <MenuItem key={indexInner} value={option.name}>
                <Checkbox checked={checked} />
                <ListItemText primary={option.name} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
        </div>
    </div>

     <div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">איקון</label>
        <div className="col-sm-10"style={{width: '50%'}}>
        <span>
            <img
              className={`${Styles.tableImage}`}
              src={`${DOMAIN}${icon}`}
              alt=""
            />
          </span>
          <label>
            <input
              style={{ display: 'none' }}
              type="file"
              accept="image/*"
              onChange={(e) => uploadImage(e, 'icon')}
            />
            <EditIcon onClick={(e) => uploadImage(e, 'icon')} />
          </label>
        </div>
    </div>

    <div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">תמונה</label>
        <div className="col-sm-10"style={{width: '50%'}}>
            <span>
              <img
                className={`${Styles.tableImage}`}
                src={`${DOMAIN}${image}`}
                alt=""
              />
            </span>
            <label>
              <input
                style={{ display: 'none' }}
                type="file"
                accept="image/*"
                onChange={(e) => uploadImage(e, 'image')}
              />
              <EditIcon onClick={(e) => uploadImage(e, 'image')} />
            </label>
        </div>
    </div>

  
  
    <div style={{width: '70%', marginTop: '50px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
    <Button
        variant="outlined"
        color="primary"
        onClick={handleSave}
        style={{ fontWeight: 'bold', width: '150px' }}
        disabled={!isEditing}
    >
        עדכן
    </Button>
    <Button
        variant="outlined"
        color="error"
        onClick={handleDelete}
        style={{ fontWeight: 'bold', width: '150px' }}
        disabled={!isEditing}
    >
     מחק קטגוריה
    </Button>
</div>

    </form>
  </Box>
  
  );
};

export default AddCategoryProduct;


