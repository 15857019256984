import Moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Table from "../../components/Tables/table";
import TableUsers from "../../components/Tables/TableUsers";
import Styles from "./User.module.css";
import { enableDisableSingleUser, getUser } from "../../../config/api";
import axios from "axios";
import { API_HUB_URL } from "../../../constants/envUrl";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';


const User = ({ isAdmin, userId, langData, langConfig }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = location.pathname;
  // const dateRangeRef = useRef(null);
  // const currentdateTime = Moment();
  const [heading, setHeading] = useState(
    pathName === "/provider" ? "Providers" : pathName === '/' ? "Users" : pathName === '/product-list' ? 'Products list' : ''
  );
  const [tableType, setTableType] = useState(
    pathName === "/provider" ? "Provider" : "User"
  );
  const [enable, setEnable] = useState(true);
  const [userData, setUserData] = useState();
  const [userFilteredData, setUserFilteredData] = useState();
  const [searchInputValue, setSearchInputValue] = useState();

  
  const [requestParams, setRequestParams] = useState({
    page: 1,
    pageSize: 0,
    totalRecords: 0,
    sortIndex: -1,
    search: "",
    sortBy: "",
    userType: tableType,
    enableDisableUsers: true,
    datetime: "",
    search: ""
  });
  const [dataLoading, setDataLoading] = useState(false);
  function roleReducer(roles, obj) {
    const role = obj.role;
    if (!roles.includes(role)) {
      // If the role is not already in the roles array, add it
      return [...roles, role];
    }
    return roles; // Otherwise, return the original roles array
  }


  const fetchData = async () => {
    try {
      setDataLoading(true);
      const response = await axios.post(getUser(), requestParams);
      const data = response.data;
      console.log('fetch_users', data)
      if (isAdmin) {
        setUserData(data.usersList);
        console.log(data.usersList.reduce(roleReducer, []), 'roles')
      } else {
        const userListById = data.usersList?.filter(user => user.id == userId)
        setUserData(userListById);
      }
      console.log(userData);
      setDataLoading(false);
    } catch (error) {
      console.error('fetch_users', error);
      setDataLoading(false);
    }
  };

  const updateUser = async (userId, IsEnable) => {
    try {
      const response = await axios.get(
        enableDisableSingleUser(userId, IsEnable)
      );
      if (response.status === 200) {
        fetchData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setFilterdValue = () => {
    const filtered =  searchInputValue ? userData?.filter(user =>
       user.firstName.toLowerCase().includes(searchInputValue.toLowerCase()) || 
       user.lastName.toLowerCase().includes(searchInputValue.toLowerCase()) || 
       user.phoneNumber.toLowerCase().includes(searchInputValue.toLowerCase())

  ) : userData;
    setUserFilteredData(filtered);

  }

  useEffect(()=>{
    setFilterdValue()
  }, [searchInputValue, userData])



  useEffect(() => {
    if (!dataLoading) {
      fetchData();
    }
  }, [requestParams]);

  useEffect(() => {
    setHeading(location.pathname === "/" ? "Users" : "Providers");
    setTableType(location.pathname === "/" ? "User" : "Provider");
    setRequestParams({
      ...requestParams,
      userType: location.pathname === "/" ? "User" : "Provider",
    });
  }, [location.pathname]);

  return (
    <>
      <div className={Styles.containerUser}>


      {/*  <div className={Styles.headingUser}>
          <h1>{langData[langConfig.userType][langConfig.lang].users.title}</h1>
        </div>*/}
        <div className={Styles.filterBox}>

          {/* <div className="d-flex justify-content-center align-items-center">
            <label className={Styles.switch}>
              <input
                type="checkbox"
                name="switch"
                value={enable}
                checked={enable}
                onChange={() => {
                  setRequestParams({
                    ...requestParams,
                    enableDisableUsers: !requestParams.enableDisableUsers,
                  });
                  setEnable(!enable);
                }}
              />
              <span className={`${Styles.slider} ${Styles.round}`}></span>
            </label>
            <label htmlFor="switch" className={Styles.switchLabel}>
              {langData[langConfig.userType][langConfig.lang].users.enableBtn}
            </label>
          </div> */}
        </div>
    
      <TextField
        label="חפש שם, פלאפון"
        variant="outlined"
        onChange={(event) => {
          setSearchInputValue(event.target.value);
        }}
      />
    
          
        <TableUsers
          data={userFilteredData}
          tableLoading={dataLoading}
          heading={heading}
          type={tableType}
          updateUser={updateUser}
          fetchData={fetchData}
          langData={langData}
          langConfig={langConfig}
          isAdmin={isAdmin}
        />
      </div>
    </>
  );
};

export default User;
