//export const API_HUB_URL = "https://localhost:7223/api";
// export const API_HUB_URL = "https://yyyback.codersuccess.com/api";
// export const API_HUB_URL = "https://yyyback.startupwr.com/api";
// export const API_HUB_URL = "https://yyyback.startupwr.com/api";
//export const API_HUB_URL = "https://localhost:7223/api";
// export const API_HUB_URL = "https://server.yyyshopping.com/api";
export const API_HUB_URL = "https://server.yyyshopping.com/api";






// export const DOMAIN  = 'https://yyyback.codersuccess.com';
// export const DOMAIN  = 'https://yyy.startupwr.com';
//  export const DOMAIN  = "https://localhost:7223/api";
export const DOMAIN  = "https://server.yyyshopping.com";

