import React, { useState, useEffect } from 'react';
import { updateUserDetails, getUserData, getUser, getUserById, removeUserById } from "../../../config/api";
import axios from "axios";
import { TextField, Button, Typography, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom";

const EditUser = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const pathName = location.pathname;
    const [userDetails, setUserDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        birthday: '',
        creationDate: '',
        numberOfOrders: 0,
        totalOrders: 0,
    });
    const { id } = useParams();
    const [isEditing, setIsEditing] = useState(true);
    const [user, setUser] = useState(null);

    const [tableType, setTableType] = useState(
        pathName === "/provider" ? "Provider" : "User"
    );
    const [requestParams, setRequestParams] = useState({
        page: 1,
        pageSize: 0,
        totalRecords: 0,
        sortIndex: -1,
        search: "",
        sortBy: "",
        userType: tableType,
        enableDisableUsers: true,
        datetime: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };


// Utility function to format the date for the server
const formatDateForServer = (date) => {
    if (!date) {
      return null;  // Handle null or undefined dates app
    }
  
    const formattedDate = new Date(date);
    const day = formattedDate.getDate().toString().padStart(2, '0');
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');  // Month is zero-based
    const year = formattedDate.getFullYear();
  
    return `${day}-${month}-${year}`;
  };

    const handleSave = async () => {
        try {
            const formattedBirthday = formatDateForServer(userDetails.birthday);
            const formattedCreationDate = formatDateForServer(userDetails.creationDate);


            const response = await axios.post(updateUserDetails(), {
                userId: id,
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
                email: userDetails.email,
                phoneNumber: userDetails.phoneNumber,
                birthdate: formattedBirthday,
                creationDate : formattedCreationDate
            });

            if (response.data.result) {
                navigate('/');
            } else {
                alert('Error updating user data');
            }
        } catch (error) {
            console.error('Error updating user data:', error);
            alert(`Error updating user data: ${error.message}`);
        }
    };

    const formatDateForInput = (date) => {
        const formattedDate = new Date(date).toISOString().split('T')[0];
        return formattedDate;
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(getUserById(id));
                const user = response.data;

                setUserDetails({
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    birthday: user.birthdate ? formatDateForInput(user.birthdate) : '',
                    creationDate: user.creationDate ? formatDateForInput(user.creationDate) : '',
                    numberOfOrders: user.numbersOfOrdersOfBuyer, // user.numbersOfOrders,
                    totalOrders: (user.numbersOfOrdersOfProvider ?? 0) + (user.numbersOfOrdersOfBuyer)
                });
            } catch (error) {
                console.error(error);
            }
        };

        fetchUserData();
    }, [id]);


    const handleDelete = async () => {
        try {
            const response = await axios.post(removeUserById(id));
    
            if (response.data.result) {
                alert('User removed successfully');
                navigate('/'); 
            } else {
                alert("user has orders, can't be removed");
            }
        } catch (error) {
            console.error('Error removing user:', error);
            alert(`Error removing user: ${error.message}`);
        }
    };
    

    return (
      <Box
      style={{margin: '60px'}}
   
    >
      <Typography
        variant="h5"
       sx={{ color: '#154360', marginBottom: '15px', fontWeight: '700', marginBottom: '50px' }}
      >
        עריכת משתמש
      </Typography>
      <form style={{width: '70%'}}>
        <div className="form-group row my-4">
          <label htmlFor="firstName" className="col-sm-2 col-form-label">שם פרטי</label>
          <div className="col-sm-10" style={{ width: '50%' }}>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={userDetails.firstName}
              onChange={handleInputChange}
              disabled={!isEditing}
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group row my-4">
          <label htmlFor="lastName" className="col-sm-2 col-form-label">שם משפחה</label>
          <div className="col-sm-10" style={{ width: '50%' }}>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={userDetails.lastName}
              onChange={handleInputChange}
              disabled={!isEditing}
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group row my-4">
          <label htmlFor="email" className="col-sm-2 col-form-label">דואר אלקטרוני</label>
          <div className="col-sm-10" style={{ width: '50%' }}>
            <input
              type="email"
              id="email"
              name="email"
              value={userDetails.email}
              onChange={handleInputChange}
              disabled={!isEditing}
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group row my-4">
          <label htmlFor="phoneNumber" className="col-sm-2 col-form-label">פלאפון</label>
          <div className="col-sm-10" style={{ width: '50%' }}>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={userDetails.phoneNumber}
              onChange={handleInputChange}
              disabled={!isEditing}
              className="form-control"
              dir="rtl"
            />
          </div>
        </div>
      </form>
      <div style={{width: '50%', display: 'flex', justifyContent: 'center', gap: '10px', marginTop:'80px'}}>
    <Button
        variant="outlined"
        color="primary"
        onClick={handleSave}
        style={{ fontWeight: 'bold', width: '150px' }}
        disabled={!isEditing}
    >
        שמור
    </Button>
    <Button
        variant="outlined"
        color="error"
        onClick={handleDelete}
        style={{ fontWeight: 'bold', width: '150px' }}
        disabled={!isEditing}
    >
        מחק משתמש
    </Button>
</div>
    </Box>
    
      
    );
};

export default EditUser;
