import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Styles from '../../components/Tables/table.module.css';
import axios from 'axios';
import { uploadFile, UpdateCreateProduct } from '../../../config/api';
import { DOMAIN } from '../../../constants/envUrl';
import DeleteIcon from '@mui/icons-material/Delete';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Styles2 from './edit-product.module.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  Box,  Typography
} from '@mui/material';
function stringifyArrays(obj) {
  const newObj = { ...obj };

  function recurse(current) {
    for (const key in current) {
      if (Array.isArray(current[key]) && key !== 'categories') {
        current[key] = JSON.stringify(current[key]);
      } else if (typeof current[key] === 'object' && current[key] !== null) {
        recurse(current[key]);
      }
    }
  }

  recurse(newObj);

  return newObj;
}

function EditProduct({ userId, langData, langConfig }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { product, mainCategories } = location.state;
  const [productData, setProductData] = useState(product);

  const handleInputEdit = (e, id, mainKeyName, name) => {
    let { value } = e.target;

    if (mainKeyName === 'categories') {
      setProductData({ ...productData, [mainKeyName]: `|${value}` });
    } else if (Array.isArray(productData[mainKeyName])) {
      const updatedProduct = productData[mainKeyName]?.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [name]: value,
          };
        } else {
          return item;
        }
      });

      setProductData({
        ...productData,
        [mainKeyName]: updatedProduct,
      });
    } else {
      setProductData({ ...productData, [mainKeyName]: value });
    }
  };

  const addImage = async (event) => {
    const formData = new FormData();
    const file = event.target.files[0];
    formData.append('image', file);
    try {
      const response = await axios.post(uploadFile(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status !== 200) return;
      const data = response.data;
      const updatedImagesArr = productData.images?.replace(/[\[\]]/g, '') + `,/images/${data}`;
      setProductData({ ...productData, images: updatedImagesArr });
    } catch (err) {
      console.log(err);
    }
  };

  const addImages = async (event) => {
    const files = Array.from(event.target.files);

    let imagesString = '';
    for (let i = 0; i < files.length; i++) {
      imagesString = await uploadImage(files[i], imagesString);
    }
    setProductData({ ...productData, images: imagesString });
  };

  async function uploadImage(file, imagesString) {
    try {
      const formData = new FormData();
      formData.append('image', file);
      const response = await axios.post(uploadFile(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status !== 200) return imagesString;
      const data = response.data;
      imagesString = imagesString === '' ? `/images/${data}` : imagesString.replace(/[\[\]]/g, '') + `,/images/${data}`;
      return imagesString;
    } catch (err) {
      console.log(err);
      return imagesString;
    }
  }

  const deleteImage = (index) => {
    const confirmed = window.confirm("Are you sure you want to remove this image?");
    if (confirmed) {
      const updatedImagesArr = productData.images.split(',').filter((item, i) => i !== index).join(',');
      setProductData({ ...productData, images: updatedImagesArr });
    }
  };

  const addItem = (mainKeyName) => {
    let updatedProduct;

    if (mainKeyName === 'variations') {
      updatedProduct = {
        id: productData[mainKeyName].length,
        name: '',
        price: '',
        discountPrice: '',
      };
    } else if (mainKeyName === 'deliveryTypes') {
      updatedProduct = {
        id: productData[mainKeyName].length,
        name: '',
        price: '',
      };
    } else if (mainKeyName === 'expandedDescription') {
      updatedProduct = {
        id: productData[mainKeyName].length,
        title: '',
        text: '',
      };
    }

    setProductData({
      ...productData,
      [mainKeyName]: [...productData[mainKeyName], updatedProduct],
    });
  };

  const removeItem = (mainKeyName, id) => {
    const confirmed = window.confirm("Are you sure you want to remove this item?");
    if (confirmed) {
      const updatedProduct = productData[mainKeyName].filter((item) => item.id !== id);
      setProductData({ ...productData, [mainKeyName]: updatedProduct });
    }
  };

  const postProductUpdate = async () => {
    const updatedProductData = {
      ...productData,
      providerId: userId,
      price: +productData.price,
      discountPrice: +productData.discountPrice,
      categoryId: productData.categories.replace("|", ""),
    };
    const modifiedObject = stringifyArrays(updatedProductData);
    try {
      const response = await axios.post(UpdateCreateProduct(), modifiedObject);
      if (response.data.result) return navigate(-1);
      alert('Something went wrong editing the product');
    } catch (err) {
      console.log(err);
      alert('Something went wrong editing the product');
    }
  };

  return (
    <Box
    style={{margin: '60px'}}
 
  >
    
    <form style={{width: '90%'}}></form>
        <h3 style={{ fontSize: '36px', fontStyle: 'normal', fontWeight: '700', color: '#154360', marginTop: '5vh' }}>עריכת מוצר</h3>
        <Button style={{ width: '50px', position: 'absolute', right: '195vh', top: '30vh' }} className='btn-light btn-outline-dark mt-3'>
          <ArrowBackIosIcon onClick={() => navigate(-1)} />
        </Button>
        <div className="content gap-5 d-flex flex-column mt-3">


      <div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">קוד מוצר</label>
        <div className="col-sm-10"style={{width: '50%'}}>
            <input
                type="text"
              //  name="categoryName"
              value={productData.id}
              onChange={(e) => handleInputEdit(e, productData.id, 'name', '')}
               className="form-control"
            />
        </div>
      </div>
   

  
    

   


         <div className="form-group row">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">קטגוריה</label>
        <div className="col-sm-10"style={{width: '50%'}}>
        <FormControl fullWidth>
                <InputLabel id="edit-product-category">
                </InputLabel>
                <Select
                  fullWidth
                  labelId="edit-product-category"
                  id="edit-product-category-sel"
                  value={productData.categories?.replace('|', '') || ""}
                  onChange={(e) => {
                    handleInputEdit({ target: { value: e.target.value } }, productData.id, 'categories', '')
                  }}
                >
                  {mainCategories.map((category) => (
                    <MenuItem value={category.value} key={category.value}>
                      {category.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        </div>
      </div>
   
    


          <div className="form-group row">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">שם מוצר</label>
        <div className="col-sm-10"style={{width: '50%'}}>
            <input
                type="text"
              //  name="categoryName"
                value={productData.name}
                onChange={(e) => handleInputEdit(e, productData.id, 'name', '')}
                className="form-control"
            />
        </div>
         </div>
   


         <div className="form-group row">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">תמונת מוצר</label>
        <div className="col-sm-10"style={{width: '50%'}}>
        <div className='block d-flex gap-3' style={{ marginRight: '25px' }}>
            <h5 className={` mb-0 ${Styles2.blockTitle}`}>    <label>
              <input
                style={{ width: 0, position: "absolute", left: "100%" }}
                type="file"
                accept=".jpg, .jpeg, .png, .gif, .svg"
                multiple
                onChange={addImages}
              />
              <span className='btn btn-primary' style={{ marginRight: '15px' }}>
                {langData[langConfig.userType][langConfig.lang].editProduct.buttons.addBtn}
              </span>
            </label>
            </h5>
            <div className={`block__content ${Styles2.imagesContainer} gap-3`}>
              <div className={`${Styles2.imagesHolder}`}>
                {productData.images !== "" && productData.images.split(',').map((imageUrl, index) => {
                  if (imageUrl !== "[]") {
                    return (
                      <div className={Styles2.imageWrapper} key={imageUrl}>
                        <span className={Styles2.removeWrapper}>
                          <DeleteIcon onClick={() => deleteImage(index)} />
                        </span>
                        <img className={Styles2.productImage} src={`${DOMAIN}${imageUrl}`} alt="" />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
         </div>
   



    
        {/*  <div className='block d-flex align-items-start' style={{ marginRight: '25px' }}>
            <h5 className={`mb-0 ${Styles2.blockTitle}`}>
              {langData[langConfig.userType][langConfig.lang].editProduct.productVariations}
              <Button onClick={() => addItem('variations')} style={{ marginRight: '25px' }}>
                {langData[langConfig.userType][langConfig.lang].editProduct.buttons.addBtn}
              </Button>
            </h5>
          </div>
          <div className='block d-flex gap-3 align-items-start' style={{ marginRight: '25px' }}>
            <h5 className={`text-end mb-0 ${Styles2.blockTitle}`}>
              {langData[langConfig.userType][langConfig.lang].editProduct.prodDeliveryTypes}
              <Button onClick={() => addItem('deliveryTypes')} style={{ marginRight: '25px' }}>
                {langData[langConfig.userType][langConfig.lang].editProduct.buttons.addBtn}
              </Button>
            </h5>
          </div>*/}


<div className="form-group row">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">מחיר</label>
        <div className="col-sm-10"style={{width: '50%'}}>
            <input
                 value={productData.price ?? ""}
                 type="number"
                 onChange={(e) => handleInputEdit(e, productData.id, 'price')}
                className="form-control"
            />
        </div>
    </div>
   
    <div className="form-group row">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">מחיר מוזל</label>
        <div className="col-sm-10"style={{width: '50%'}}>
            <input
                value={productData.discountPrice}
                type="number"
                onChange={(e) => handleInputEdit(e, productData.id, 'discountPrice')}
                className="form-control"
            />
        </div>
    </div>



    <div className="form-group row">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">תיאור</label>
        <div className="col-sm-10"style={{width: '50%'}}>
            <textarea
                value={productData.discountPrice}
                value={productData.description ?? ""}
                as="textarea" rows={3}
                onChange={(e) => handleInputEdit(e, productData.id, 'description')}
                className="form-control"
            />
        </div>
    </div>



      
       {/*   <div className='block d-flex align-items-start' style={{ marginRight: '25px' }}>
            <h5>
              {langData[langConfig.userType][langConfig.lang].editProduct.expandedDescription}
              <Button onClick={() => addItem('expandedDescription')} style={{ marginRight: '25px' }}>
  {langData[langConfig.userType][langConfig.lang].editProduct.buttons.addBtn}
</Button>

            </h5>
            <div className={`block__content d- gap-3 align-items-start flex-row-reverse flex-wrap`}>
              {Array.isArray(productData.expandedDescription) && productData.expandedDescription.map((description) => (
                <div key={description.id} className="mb-3">
                  <Form className='d-flex gap-3 align-items-start'>
                    <Button className={`${Styles2.removeItem} ${Styles2.removeItemDesc}`} onClick={() => removeItem('expandedDescription', description.id)}>
                      {langData[langConfig.userType][langConfig.lang].editProduct.buttons.removeBtn}
                    </Button>
                    <Form.Group className={Styles2.descTitle}>
                      <Form.Label>
                        {langData[langConfig.userType][langConfig.lang].editProduct.title}
                      </Form.Label>
                      <Form.Control type="text"
                        value={description.title}
                        onChange={(e) => handleInputEdit(e, description.id, 'expandedDescription', 'title')}
                      />
                    </Form.Group>
                    <Form.Group className={Styles2.descTextArea}>
                      <Form.Label>
                        {langData[langConfig.userType][langConfig.lang].editProduct.text}
                      </Form.Label>
                      <Form.Control
                        value={description.text}
                        as="textarea" rows={5}
                        onChange={(e) => handleInputEdit(e, description.id, 'expandedDescription', 'text')}
                      />
                    </Form.Group>
                  </Form>
                </div>
              ))}
            </div>
          </div>
          <div className='block d-flex gap-3' style={{ marginRight: '25px' }}>
            <h5>
              {langData[langConfig.userType][langConfig.lang].editProduct.time}
            </h5>
            <div className={`block__content d-flex gap-3 align-items-center`}>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Control type="text"
                    value={productData.time}
                    onChange={(e) => handleInputEdit(e, productData.id, 'time')}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>
          <div className='block d-flex gap-3'>
            <h5>
              {langData[langConfig.userType][langConfig.lang].editProduct.categoryId}
            </h5>
            <div className={`block__content d-flex gap-3 align-items-center`}>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Control type="text"
                    value={productData.categoryId}
                    onChange={(e) => handleInputEdit(e, productData.id, 'categoryId')}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>*/}
        </div>
<div style={{display: 'flex', width: '70%', marginTop: '40px'}}>

        <Button
  className='mb-5'
  onClick={postProductUpdate}
  style={{ display: 'block', margin: '0 auto', width: '90px' }}
>
  {langData[langConfig.userType][langConfig.lang].editProduct.buttons.saveBtn}
</Button>
</div>
    
    </Box>
  );
}

export default EditProduct;
