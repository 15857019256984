import React, { useState, useEffect } from 'react';
import { SaveOrder, getUserData, getUser, getOrderById, removeOrderById, updateOrder } from "../../../config/api";
import axios from "axios";
import { TextField, Button, Typography, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom";
import { getProviderOrders, updateOrderStatus, updateViewOrder, getAllOrders } from '../../../config/api';
import { Form } from 'react-bootstrap'
import { Save } from '@mui/icons-material';


const EditOrder = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const pathName = location.pathname;
    const [orderDetails, setOrderDetails] = useState({
        buyerUserId: '',
        creationDate: '',
        ownerFullName: '',
        ownerUserId: '',
        providerUserId: "",
        amount: "",
        status: "",

    });
    const { id } = useParams();
    const [isEditing, setIsEditing] = useState(true);
    const [user, setUser] = useState(null);
    const [orders, setOrders] = useState([])
    const [order, setOrder] = useState([])

    const orderStatuses = ["pending", "processing", "shipped", "delivered", "paidProvider"]


    const [tableType, setTableType] = useState(
        pathName === "/provider" ? "Provider" : "User"
    );
    const [requestParams, setRequestParams] = useState({
        page: 1,
        pageSize: 0,
        totalRecords: 0,
        sortIndex: -1,
        search: "",
        sortBy: "",
        userType: tableType,
        enableDisableUsers: true,
        datetime: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOrderDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(getOrderById(id));
                const order = response.data.order;

                setOrderDetails({
                    buyerUserId: order?.buyerUserId,                    creationDate: order?.creationDate,
                    ownerUserId: order?.ownerUserId,
                    ownerFullName: order?.ownerFullName,
                    providerUserId: order?.providerUserId ? order?.providerUserId : " ",
                    amount: order?.amount,
                    status: order?.status,
                });
            } catch (error) {
                console.error(error);
            }
        };

        fetchUserData();
    }, [id]);






 

    const handleSave = async () => {
        try {
    
    
            const response = await axios.post(updateViewOrder(), {
                Id: id,
                ownerUserId: orderDetails.ownerUserId,
                buyerUserId: orderDetails.buyerUserId,
                status: orderDetails.status,
                amount: orderDetails.amount
            });
    
            if (response.data.result) {
                navigate('/orders');
            } else {
                alert('Error saving order');
            }
        } catch (error) {
            console.error('Error saving order:', error);
            alert(`Error saving order: ${error.message}`);
        }
    };
    
 




    const handleDelete = async () => {
        try {
            const response = await axios.post(removeOrderById(id));

            if (response.data.result) {
                alert('order removed successfully');
                navigate('/orders');
            } else {
                alert('Error removing user');
            }
        } catch (error) {
            console.error('Error removing user:', error);
            alert(`Error removing user: ${error.message}`);
        }
    };


    return (
        <Box
        sx={{
            maxWidth: 350,
            padding: 6,
                  margin: 5,
            marginLeft: 8.5,
            border: '3px solid #e2e3e5',
          }}
      >         
<Typography variant="h5" sx={{ color: '#0d6efd', textAlign: 'center', marginBottom: '32px', fontWeight: 'bold' }}>
  עריכת הזמנה
</Typography>            <form >
                <TextField
                    fullWidth
                    label="מספר משתמש קונה"
                    name="buyerUserId"
                    value={orderDetails.buyerUserId}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    margin="normal"
                />
            
                <TextField
                    fullWidth
                    label="משתמש בעל הזמנה"
                    name="ownerFullName"
                    value={orderDetails.ownerFullName}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    margin="normal"
                    dir="rtl"
                />
                <TextField
                    fullWidth
                    label="מספר משתמש ספק"
                    name="providerUserId"
                    value={orderDetails.providerUserId}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="סכום"
                    name="amount"
                    value={orderDetails.amount}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    margin="normal"
                />
                <Form.Group controlId="statusSelect">
                    <Form.Label>סטטוס</Form.Label>
                    <Form.Select
                        value={orderDetails.status || ''}
                        onChange={handleInputChange}
                        name="status"
                    >
                        {orderStatuses && orderStatuses.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </Form.Select>
                </Form.Group>



            </form>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                sx={{ mt: 4, mb: 0, fontWeight: 'bold', width: '100%' }}
                disabled={!isEditing}
            >
                עדכן
            </Button>

        </Box>
    );
};

export default EditOrder;
