import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { addNewProvider } from '../../../config/api';
import { useNavigate } from "react-router-dom";

function AddProvider({
  userId,
  userType,
  langData,
  langConfig
}) {
  const [providerDetails, setProviderDetails] = useState({

  });
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'birthdate') {
      // Format date to dd-mm-yyyy
      const formattedDate = value.split('-').reverse().join('-');
      setProviderDetails(prevState => ({
        ...prevState,
        [name]: formattedDate
      }));
    } else {
      setProviderDetails(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };
  

  const handleSave = async () => {
 //   const formattedDate = providerDetails.birthdate ? new Date(providerDetails.birthdate).toLocaleDateString('en-GB') : '';
  //  alert(providerDetails.birthdate)
    const requestData = {
      username: providerDetails.firstName+ " "+ providerDetails.lastName,
      roleId: "Provider",
    //  birthdate: formattedDate,
            ...providerDetails,

    };
    //  navigate('/add-provider')
  //   console.log('addProvider: ', newProvider)
      // newProvider[0].creationDate = new Date().toISOString()
      try {
        const response = await axios.post(addNewProvider(), requestData)
        console.log('response.result', response)
        if (response.data.result) {
       alert("ספק נוסף בהצלחה")
       navigate("/provider")
        //  fetchData()
        //  setIsAddProvider(false)
        } else if (response.data.result === false){
       //   alert(response.data.message);
        } else {
          alert("כל השדות חובה!");
        }
  
      } catch (error) {
        console.log(error);
        alert("כל השדות חובה!");
      }
    }

  return (
    <>
      <div style={{ margin: '60px' }}>
        <form style={{width: '70%'}}>
          <div className="form-group row my-4">
            <label htmlFor="firstName" className="col-sm-2 col-form-label">שם פרטי</label>
            <div className="col-sm-10">
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={providerDetails.firstName}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row my-4">
            <label htmlFor="lastName" className="col-sm-2 col-form-label">שם משפחה</label>
            <div className="col-sm-10">
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={providerDetails.lastName}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row my-4">
            <label htmlFor="email" className="col-sm-2 col-form-label">דואר אלקטרוני</label>
            <div className="col-sm-10">
              <input
                type="text"
                id="email"
                name="email"
                value={providerDetails.email}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row my-4">
            <label htmlFor="phoneNumber" className="col-sm-2 col-form-label">פלאפון</label>
            <div className="col-sm-10">
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                value={providerDetails.phoneNumber}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row my-4">
            <label htmlFor="companyName" className="col-sm-2 col-form-label">שם חברה</label>
            <div className="col-sm-10">
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={providerDetails.companyName}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>
          
          <div className="form-group row my-4">
            <label htmlFor="companyName" className="col-sm-2 col-form-label">כתובת חברה</label>
            <div className="col-sm-10">
              <input
                type="text"
                id="companyAdress"
                name="companyAdress"
                value={providerDetails.companyAdress}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>
          
 
          <div className="form-group row my-4">
            <label htmlFor="dateOfBirth" className="col-sm-2 col-form-label">תאריך לידה</label>
            <div className="col-sm-10">
              <input
                type="date"
                id="birthdate"
                name="birthdate"
                value={providerDetails.dateOfBirth}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>


          <div className="form-group row my-4">
            <label htmlFor="password" className="col-sm-2 col-form-label">סיסמה</label>
            <div className="col-sm-10">
              <input
                type="password"
                id="password"
                name="password"
                value={providerDetails.password}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>
          <div style={{textAlign: 'center' }}>
  <Button
    color="primary"
    onClick={handleSave}
    style={{ fontWeight: 'bold', width: '120px', display: 'inline-block' }}
  >
    שמור
  </Button>
</div>

        </form>
  

      </div>
    </>
  );
}

export default AddProvider;
