import React, { useState, useEffect } from 'react';
import { updateMainCategory, getMainCategoryById, removeMainCategory, uploadFile } from "../../../config/api";
import axios from "axios";
import { TextField, Button, Typography, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom";
import { DOMAIN } from '../../../constants/envUrl'
import EditIcon from '@mui/icons-material/Edit';
import Styles from "../Categories/Categories.module.css";

const EditMainCategory = ({ setActiveTab }) => {
  const location = useLocation();
    const { id } = useParams();
    const [isEditing, setIsEditing] = useState(true);
    const [mainCategoryDetails, setMainCategoryDetails] = useState({
        name: '',
        description: '',
        icon: '',
        image: ''
    });
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMainCategoryDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };
    const [icon, setIcon] = useState('');
    const [image, setImage] = useState('');



    const handleSave = async () => {
        try {
            const response = await axios.post(updateMainCategory(), {
                id: id,
                name: mainCategoryDetails.name,
                description: mainCategoryDetails.description,
                imageLink: image,
                iconLink: icon,
            });

            if (response.data.result) {
           //   setActiveTab(1);
                navigate('/product-categories-management');
            } else {
                alert('Error updating user data');
            }
        } catch (error) {
            console.error('Error updating user data:', error);
            alert(`Error updating user data: ${error.message}`);
        }
    };

    const fetchUserData = async () => {
        try {
            const response = await axios.get(getMainCategoryById(id));
            const mainCategoryDetails = response.data.mainCategory;

            setMainCategoryDetails({
                name: mainCategoryDetails.categoryName,
                description: mainCategoryDetails.description,
                icon: mainCategoryDetails.categoryIcon,
                image: mainCategoryDetails.categoryImage,
                categoryAdminName: mainCategoryDetails.categoryAdminName
            });
            setIcon(`${mainCategoryDetails?.categoryIcon}`);
            setImage(`${mainCategoryDetails?.categoryImage}`);


        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [id]);

    const handleDelete = async () => {
        try {
            const response = await axios.post(removeMainCategory(id));

            if (response.data.result) {
                alert('User removed successfully');
                navigate('/');
            } else {
                alert('קטגוריה נמחקה');
            }
        } catch (error) {
            console.error('Error removing user:', error);
            alert(`Error removing user: ${error.message}`);
        }
    };

    const uploadImage = async (event, type) => {
      const file = event.target.files && event.target.files[0];
  
      if (file) {
        try {
          const formData = new FormData();
          formData.append('image', file);
  
          const response = await axios.post(uploadFile(), formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          debugger
          const data = response.data;
          if (response.status !== 200) return;
          if (type === 'icon') setIcon(`/images/${data}`);
          if (type === 'image') setImage(`/images/${data}`);
        } catch (error) {
          console.error('Error uploading image:', error);
          throw error;
        }
      } else {
        console.error('No file selected');
      }
    };

    return (
      <Box
      style={{margin: '60px'}}
   
    >
      <Typography
        variant="h5"
       sx={{ color: '#154360', marginBottom: '15px', fontWeight: '700', marginBottom: '50px' }}
      >
        עריכת קטגוריה ראשית
      </Typography>
      <form style={{width: '90%'}}>


      <div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">שם קטגוריה</label>
        <div className="col-sm-10"style={{width: '50%'}}>
            <input
                type="text"
                name="name"
                value={mainCategoryDetails.categoryAdminName}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="form-control"
            />
        </div>
    </div>

    <div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">תיאור קטגוריה</label>
        <div className="col-sm-10"style={{width: '50%'}}>
            <input
                type="text"
                name="description"
                value={mainCategoryDetails.description}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="form-control"
            />
        </div>
    </div>

    <div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">איקון</label>
        <div className="col-sm-10"style={{width: '50%'}}>
        <span className={Styles.tableImageCover}>
                <img className={`${Styles.tableImage}`} src={`${DOMAIN}${icon}`} alt="" />
              </span>
  
              <label>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  accept="image/*"
                  onChange={(e) => uploadImage(e, 'icon')}
                />
  
                <EditIcon onClick={(e) => uploadImage(e, 'icon')} />
              </label>
        </div>
    </div>

    <div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">תמונה</label>
        <div className="col-sm-10"style={{width: '50%'}}>
             <span>
                <img className={`${Styles.tableImage}`} src={`${DOMAIN}${image}`} alt="" />
              </span>
  
              <label>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  accept="image/*"
                  onChange={(e) => uploadImage(e, 'image')}
                />
  
                <EditIcon onClick={(e) => uploadImage(e, 'image')} />
              </label>
        </div>
    </div>

    <div style={{width: '70%', marginTop: '50px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
    <Button
        variant="outlined"
        color="primary"
        onClick={handleSave}
        style={{ fontWeight: 'bold', width: '150px' }}
        disabled={!isEditing}
    >
        עדכן
    </Button>
    <Button
        variant="outlined"
        color="error"
        onClick={handleDelete}
        style={{ fontWeight: 'bold', width: '150px' }}
        disabled={!isEditing}
    >
     מחק קטגוריה
    </Button>
</div>

    
  

        </form>
      </Box>
        
    );
};

export default EditMainCategory;
